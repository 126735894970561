(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrProjectDetailController', TrProjectDetailController);

    TrProjectDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrProject', 'User', 'ProjectType', 'TrProjectImages', 'TrProjectRecordings', 'AlertService', '$window', '$localStorage', 'getProjectImage', 'Configs', 'SummaryPost', 'TrRouteListByProjectId', 'TerrainType', 'TaskType', 'TaskLogProjectReportData', 'taskConfiguration', 'RowType', 'MaterialDates', 'MaterialByDates', 'ParseLinks','BaseUrl'];

    function TrProjectDetailController($scope, $rootScope, $stateParams, previousState, entity, TrProject, User, ProjectType, TrProjectImages, TrProjectRecordings, AlertService, $window, $localStorage, getProjectImage, Configs, SummaryPost, TrRouteListByProjectId, TerrainType, TaskType, TaskLogProjectReportData, taskConfiguration, RowType, MaterialDates, MaterialByDates, ParseLinks,BaseUrl) {
        var vm = this;

        vm.trProject = entity;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;
        vm.showTaskLogReport = showTaskLogReport;
        var unsubscribe = $rootScope.$on('trakeyeApp:trProjectUpdate', function (event, result) {
            vm.trProject = result;
        });
        vm.showProjectAttachment = false
        var locations = {}

        Configs.get("ProjectImage", function (response) {
            if (response.length > 0) {
                if (response[0].configValue == "True") {
                    vm.showProjectAttachment = true
                }
            }
        })

        vm.imageInNewTab = function (img) {
            var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' +
                img +
                '">'
            );
            imgWindow.document.close();
        };

        $scope.newTabDisplay = function (id) {
            var request = new XMLHttpRequest();
            request.open('GET', "api/trakeyeprojectimages/" + vm.trProject.id + "/" + id, true);
            request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.authenticationToken);
            request.responseType = 'blob';
            request.onload = response;

            function response(e) {
                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(this.response);
                    window.open(imageUrl, "_blank")
                }
            }
            request.send();
        }

        $scope.$on('$destroy', unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };

        $scope.allimages = false;
        //		$scope

        $scope.toggle = function (id) {
            switch (id) {
                case "image":
                    $scope.allimages = !$scope.allimages;
                    vm.showImages = !vm.showImages;
                    if ($scope.allimages) {
                        TrProjectImages.query(
                            {
                                projectId: vm.trProject.id,
                            },
                            onSuccess,
                            onError
                        );

                        function onSuccess(data, headers) {
                            // console.log('Recived data-----------');

                            vm.projectImages = data.filter(function (projectImages) {
                                return projectImages.image
                            });

                        }

                        function onError(error) {
                            AlertService.error(error.data.message);
                        }
                    }
                    break;

                case "termination":
                    $scope.terminationDetails = !$scope.terminationDetails;
                    vm.showTermination = !vm.showTermination;
                    break;

                case "fms":
                    $scope.fmsDetails = !$scope.fmsDetails;
                    vm.showFMS = !vm.showFMS;
                    break;

                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                case "cable":
                    $scope.cableDetails = !$scope.cableDetails;
                    vm.showCable = !vm.showCable;
                    break;

                case "pdf":
                    $scope.allpdf = !$scope.allpdf;
                    vm.showPdf = !vm.showPdf;
                    if ($scope.allpdf) {
                        getProjectImage.get(vm.trProject.id, function (res) {
                            vm.attachments = res;
                        });
                    }

                    break;

                default:
                    console.log("");
            }



			/*
			
			$scope.allimages = !$scope.allimages;
			
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/project_"+vm.trProject.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			
			if ($scope.allimages) {
				TrProjectImages.query({
					projectId : vm.trProject.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					// console.log('Recived data-----------');
					vm.projectImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		*/};

        //		$scope.playAudio = function() {
        ////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/project_"+vm.trProject.id+".mp3";
        //            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/project_15570572.mp3";
        //	        var audio = new Audio(urlStr);
        //	        audio.play();
        //	    };


        function decodeImage(img) {
            return window.atob(img);
        }

        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };


        $scope.setTab = function (newTab) {


            if (newTab == 2) {
                //Comment Box
                vm.objectComments = {}
                vm.objectComments.entityId = entity.id
                vm.objectComments.entityName = "PROJECT"
            }



            if (newTab == 8) {
                vm.existingMap = []
                vm.existingMapRow = []

                taskConfiguration.getTerrainConfiguration(vm.trProject.id, function (data) {
                    vm.existingMap = data

                    $scope.groupedData = {};

                    // Group data based on tree
                    data.forEach(function (item) {
                        var treeName = item.terrainType.name;
                        if (!$scope.groupedData[treeName]) {
                            $scope.groupedData[treeName] = [];
                        }
                        $scope.groupedData[treeName].push(item.taskType.name);
                    });
                    //console.log($scope.groupedData)
                    /*  if(data.length){
                         data.forEach(function(item){

                             if(item.terrainType.id)
                             vm.existingMap.push({terrain:item.terrainType,task:item.taskType})
                         })
                     } */
                })

                taskConfiguration.getRoWConfiguration(vm.trProject.id, function (data) {
                    vm.existingMapRow = data

                    $scope.groupedDataRow = {};

                    // Group data based on tree
                    data.forEach(function (item) {
                        var treeName = item.rowType.name;
                        if (!$scope.groupedDataRow[treeName]) {
                            $scope.groupedDataRow[treeName] = [];
                        }
                        $scope.groupedDataRow[treeName].push(item.taskType.name);
                    });
                })
                vm.selectedTerrain = null
                vm.mappedTypes = []
                vm.selectedRow = null
                vm.mappedTypesRow = []

                TerrainType.query({
                    page: 0,
                    size: 100,
                    sort: 'update_date,desc'
                }, function onSuccess(data, headers) {
                    vm.terrainTypes = data;
                }, onError);

                TaskType.query({
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: 'update_date,desc'
                }, function onSuccess(data, headers) {
                    vm.taskTypes = data;
                    vm.taskTypesRow = data
                }, onError);

                RowType.query({
                    page: 0,
                    size: 100,
                    sort: 'update_date,desc'
                }, function onSuccess(data, headers) {
                    vm.rowTypes = data;
                }, onError);
            }

            if (newTab == 3) {
                if (vm.dailyLogsTxSummary.routeTasks.length) {
                    $scope.tab = 4;
                    showTaskLogReport(vm.trProject.id);
                } else if (vm.dailyLogsTxSummary.sectionTasks.length) {
                    $scope.tab = 7;
                    showTaskLogReport(vm.trProject.id);
                }
            }

            $scope.tab = newTab;


        }

        SummaryPost.dailyLogsTx({
            "projectIds": [vm.trProject.id]
        }, function (response) {
            vm.dailyLogsTxSummary = response
        })

        SummaryPost.mtrlTx({
            "projectIds": [vm.trProject.id]
        }, function (response) {
            vm.mtrlTxSummary = response
        })

        SummaryPost.resTx({
            "projectIds": [vm.trProject.id]
        }, function (response) {
            vm.resTxSummary = response
        })



        function onError(error) {
            AlertService.error(error.data.message);
        }

        $scope.terrain = {
            taskType: {
                search: "Search",
                nothingSelected: "Select Task Type",
                selectAll: "All",
                selectNone: "None",
                Done: "Done",
            }
        };

        $scope.row = {
            taskType: {
                search: "Search",
                nothingSelected: "Select Task Type",
                selectAll: "All",
                selectNone: "None",
                Done: "Done",
            }
        };


        vm.clickSection = function (str) {

            if (str == 'terrain') {
                if (vm.mappedTypes.length) {
                    vm.mappedTypes.forEach(function (item, index) {
                        if (item.terrain.id == vm.selectedTerrain.id) {
                            vm.mappedTypes.splice(index, 1)
                        }
                    })
                    vm.mappedTypes.push({ terrain: vm.selectedTerrain, task: vm.selectedTaskType })
                } else {
                    vm.mappedTypes.push({ terrain: vm.selectedTerrain, task: vm.selectedTaskType })

                }
            }

            if (str == 'row') {
                if (vm.mappedTypesRow.length) {
                    vm.mappedTypesRow.forEach(function (item, index) {
                        if (item.row.id == vm.selectedRoW.id) {
                            vm.mappedTypesRow.splice(index, 1)
                        }
                    })
                    vm.mappedTypesRow.push({ row: vm.selectedRoW, task: vm.selectedTaskTypeRow })
                } else {
                    vm.mappedTypesRow.push({ row: vm.selectedRoW, task: vm.selectedTaskTypeRow })

                }
            }

        }

        vm.terrainChange = function () {
            vm.taskTypes.forEach(function (t) {
                t.ticked = false
            })

            vm.mappedTypes.forEach(function (item) {
                if (item.terrain.id == vm.selectedTerrain.id) {
                    item.task.forEach(function (e) {
                        vm.taskTypes.forEach(function (t) {
                            if (e.id == t.id) {
                                t.ticked = true
                            }
                        })
                    })
                }
            })
        }
        $scope.deleteMap = function (id, str) {

            if (str == 'row') {
                vm.mappedTypesRow.splice(id, 1)
                $('#selectRow').val(null).trigger('change.select2');


                vm.taskTypesRow.forEach(function (t) {
                    t.selected = false
                })
            }

            if (str == 'terrain') {
                vm.mappedTypes.splice(id, 1)
                $('#selectedTerrain').val(null).trigger('change.select2');
                vm.taskTypes.forEach(function (t) {
                    t.ticked = false
                })
            }

        }
        $(".select2").select2();

        function createMetaTag(attrName, attrValue, content) {
            var meta = document.createElement('meta');
            meta.setAttribute(attrName, attrValue);
            meta.setAttribute('content', content);
            return meta;
        }

        function createTable(classNames) {
            var table = document.createElement('table');
            table.setAttribute('border', '1');
            table.setAttribute('cellpadding', '5');
            table.setAttribute('cellspacing', '0');
            table.setAttribute('id', 'sheet0');
            table.setAttribute('class', classNames);
            return table;
        }

        function createTableCell(tagName, textContent) {
            var cell = document.createElement(tagName);
            cell.textContent = textContent;
            return cell;
        }

        function showTaskLogReport(projectId) {
            vm.isReportGenerated = false;
            TaskLogProjectReportData.query(
                {
                    reportType: "daily",
                    projectId: projectId
                },
                onSuccess,
                onError
            );

            function onSuccess(data) {
                var htmlDoc = document.createElement('html');
                var head = document.createElement('head');
                var meta1 = createMetaTag('http-equiv', 'Content-Type', 'text/html; charset=utf-8');
                var meta2 = createMetaTag('name', 'generator', 'PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet');
                var meta3 = createMetaTag('name', 'author', 'Apache POI');
                var meta4 = createMetaTag('name', 'company', 'Microsoft Corporation');
                head.appendChild(meta1);
                head.appendChild(meta2);
                head.appendChild(meta3);
                head.appendChild(meta4);
                var body = document.createElement('body');
                var table = createTable('sheet0 gridlines');
                var thead = document.createElement('thead');
                var tbody = document.createElement('tbody');

                function createTable(classNames) {
                    var table = document.createElement('table');
                    table.setAttribute('border', '1');
                    table.setAttribute('cellpadding', '5');
                    table.setAttribute('cellspacing', '0');
                    table.setAttribute('id', 'sheet0');
                    table.setAttribute('class', classNames);
                    return table;
                }

                function createMetaTag(attrName, attrValue, content) {
                    var meta = document.createElement('meta');
                    meta.setAttribute(attrName, attrValue);
                    meta.setAttribute('content', content);
                    return meta;
                }

                function createTableCell(tag, content) {
                    var cell = document.createElement(tag);
                    cell.textContent = content;
                    cell.style.fontSize = "13px";
                    return cell;
                }

                thead.className = 'thReport';
                table.appendChild(thead);
                tbody.className = 'tdReport';
                table.appendChild(tbody);
                var styleElement = document.createElement('style');
                styleElement.textContent = "\
                .subheader {\
                    margin: auto;\
                }\
                .thReport > tr > th{\
                    text-align: center;\
                    padding: 10px;\
                }\
                .tdReport > tr > td {\
                    text-align: center; \
                    padding: 10px;\
                }\
                ";
                document.head.appendChild(styleElement);

                var colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2',];
                function getRandomColor() {
                    if (colors.length === 0) {
                        colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2'];
                    }
                    var index = Math.floor(Math.random() * colors.length);
                    var color = colors[index];
                    colors.splice(index, 1);
                    return color;
                }


                var groupedData = {};
                data.forEach(function (item) {
                    if (!groupedData[item.date]) {
                        groupedData[item.date] = [];
                    }
                    groupedData[item.date].push(item);
                });
                var names = getUniqueNames(data);
                var nameHeaderRow = document.createElement('tr');
                var serialNumberHeader = createTableCell('th', 'S.No');
                serialNumberHeader.style.fontWeight = 'bold';
                serialNumberHeader.style.backgroundColor = '#7dcfb6';
                serialNumberHeader.rowSpan = 2;
                nameHeaderRow.appendChild(serialNumberHeader);
                var dateHeader = createTableCell('th', 'Date');
                dateHeader.style.backgroundColor = '#7dcfb6';
                dateHeader.style.fontWeight = 'bold';
                dateHeader.rowSpan = 2;
                nameHeaderRow.appendChild(dateHeader);
                var typeHeaderRow = document.createElement('tr');

                names.forEach(function (name, index) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    var availableTypes = getUniqueTypes(nameData);
                    var nameHeaderCell = createTableCell('th', name);
                    nameHeaderCell.style.backgroundColor = getRandomColor();
                    nameHeaderCell.style.fontWeight = 'bold';
                    nameHeaderCell.colSpan = availableTypes.length;
                    nameHeaderRow.appendChild(nameHeaderCell);
                    availableTypes.forEach(function (type) {
                        var typeHeaderCell = createTableCell('th', type);
                        typeHeaderCell.style.backgroundColor = nameHeaderCell.style.backgroundColor;
                        typeHeaderCell.style.fontWeight = 'bold';
                        typeHeaderRow.appendChild(typeHeaderCell);
                    });
                });
                var totalProgressHeader = createTableCell('th', 'Total Progress');
                totalProgressHeader.style.backgroundColor = '#cfe0c3';
                totalProgressHeader.style.fontWeight = 'bold';
                totalProgressHeader.colSpan = 3;
                nameHeaderRow.appendChild(totalProgressHeader);
                var subHeaders = [
                    {
                        type: 'Total',
                    },
                    {
                        type: "Ducting",
                    }, {
                        type: "Validation",

                    }
                ];
                subHeaders.forEach(function (subHeader) {
                    var subHeaderCell = createTableCell('th', subHeader.type);
                    subHeaderCell.style.backgroundColor = '#cfe0c3';
                    subHeaderCell.style.fontWeight = 'bold';
                    typeHeaderRow.appendChild(subHeaderCell);
                });
                thead.appendChild(nameHeaderRow);
                thead.appendChild(typeHeaderRow);
                var totals = {};

                Object.keys(groupedData)
                    .sort(function (a, b) {
                        var dateA = a.split('-').reverse().join('-');
                        var dateB = b.split('-').reverse().join('-');
                        return new Date(dateA) - new Date(dateB);
                    })
                    .forEach(function (date, index) {
                        var totalLength = 0;
                        var totalValidation = 0;
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name && item.date === date; });
                            var ductingLength = 0;
                            var hddLength = 0;
                            var horizontalDirectionalDrilling = 0
                            var validationLength = 0;
                            nameData.forEach(function (item) {
                                if (item.type === 'Ducting') {
                                    ductingLength += item.length;
                                }
                                else if (item.type === 'HDD') {
                                    hddLength += item.length;

                                }
                                else if (item.type === 'HDD ( Horizontal Directional Drilling )') {
                                    horizontalDirectionalDrilling += item.length;
                                    console.log(horizontalDirectionalDrilling)
                                }
                                else if (item.type === 'Validation' || item.type === 'Validation (T&D)') {
                                    validationLength += item.length;
                                }
                            });
                            console.log(totalLength)
                            totalLength += (ductingLength + hddLength + horizontalDirectionalDrilling);
                            totalValidation += validationLength;

                        });
                        var tr = document.createElement('tr');
                        var serialNumberCell = createTableCell('td', index + 1);
                        serialNumberCell.style.fontSize = "13px";
                        tr.appendChild(serialNumberCell);
                        var dateCell = createTableCell('td', date);
                        dateCell.style.width = '77px'
                        dateCell.style.whiteSpace = 'nowrap';
                        dateCell.style.fontSize = "13px";
                        tr.appendChild(dateCell);
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name; });
                            var availableTypes = getUniqueTypes(nameData);

                            availableTypes.forEach(function (type) {
                                var item = groupedData[date].find(function (item) {
                                    return item.name === name && item.type === type;
                                });
                                var lengthValue = item ? item.length : 0;
                                var td = createTableCell('td', lengthValue);

                                tr.appendChild(td);
                                if (!totals[type]) {
                                    totals[type] = 0;
                                }
                                totals[type] += lengthValue;
                            });
                        });
                        var total = 0;
                        for (var i = 2; i < tr.cells.length; i++) {
                            total += parseInt(tr.cells[i].textContent);
                        }
                        var columnValueCell = createTableCell('td', total);
                        columnValueCell.colSpan = 1;
                        tr.appendChild(columnValueCell);
                        var remainingDuctingCell = createTableCell('td', totalLength);
                        tr.appendChild(remainingDuctingCell);
                        var remainingValidationCell = createTableCell('td', totalValidation);
                        tr.appendChild(remainingValidationCell);
                        if (index === 0) {
                            totals['Total Progress Ducting'] = 0;
                            totals['Total Progress Validation'] = 0;
                        }
                        totals['Total Progress Ducting'] += totalLength;
                        totals['Total Progress Validation'] += totalValidation;
                        tbody.appendChild(tr);
                    });

                var staticColumns = ['Total', 'Balance Validation', 'Balance Ducting', 'Material Issued', 'Wastage', 'Stock at Site'];
                var allAvailableTypes = [];
                names.forEach(function (name) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    allAvailableTypes.push.apply(allAvailableTypes, getUniqueTypes(nameData));
                });
                var availableTypes = allAvailableTypes;
                var maxUniqueTypesCount = availableTypes.length;
                var totalLength = maxUniqueTypesCount + subHeaders.length;

                var columnTotals = {};
                for (var i = 2; i < totalLength; i++) {
                    var total = 0;
                    for (var j = 0; j < tbody.rows.length; j++) {
                        var cellValue = parseInt(tbody.rows[j].cells[i].textContent) || 0;
                        total += cellValue;
                    }
                    columnTotals[i] = total;
                }
                staticColumns.forEach(function (column) {
                    var staticRow = document.createElement('tr');
                    var columnNameCell = createTableCell('td', column);
                    columnNameCell.colSpan = 2;
                    columnNameCell.style.backgroundColor = '#cfe0c3';
                    columnNameCell.style.fontSize = "13px";
                    columnNameCell.style.fontWeight = 'bold';
                    staticRow.appendChild(columnNameCell);
                    if (column == 'Total') {
                        for (var i = 2; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', columnTotals[i].toString());
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                        var columnValueCell = createTableCell('td', totals['Total Progress Ducting']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                        var columnValueCell = createTableCell('td', totals['Total Progress Validation']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                    } else {
                        for (var i = 0; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', '0');
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                    }
                    tbody.appendChild(staticRow);
                });
                table.appendChild(thead);
                table.appendChild(tbody);
                body.appendChild(table);
                htmlDoc.appendChild(body);
                htmlDoc.appendChild(head);
                var htmlString = htmlDoc.outerHTML;
                var containerDiv = document.getElementById("generatedHtmlContainer");
                containerDiv.innerHTML = htmlString;

                function getUniqueNames(data) {
                    var uniqueNames = [];
                    data.forEach(function (item) {
                        if (uniqueNames.indexOf(item.name) === -1) {
                            uniqueNames.push(item.name);
                        }
                    });
                    return uniqueNames;
                }
                function getUniqueTypes(data) {
                    var uniqueTypes = [];
                    data.forEach(function (item) {
                        if (uniqueTypes.indexOf(item.type) === -1) {
                            uniqueTypes.push(item.type);
                        }
                    });
                    return uniqueTypes;
                }


                // Set background color for specified cells
                var jcbCells = document.querySelectorAll('.row1 .column0'); // Select cells in row 1, column 0 (JCB)
                var hddCells = document.querySelectorAll('.row1 .column1'); // Select cells in row 1, column 1 (HDD)

                // Loop through JCB cells and set background color to light green
                jcbCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Loop through HDD cells and set background color to light green
                hddCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Get the Total row
                // Select all relevant rows
                var relevantRows = ['.row2', '.row3', '.row5', '.row6'];

                // Loop through each relevant row
                relevantRows.forEach(function (rowClass) {
                    var cells = document.querySelectorAll(rowClass + ' td');

                    // Loop through the cells in the row
                    cells.forEach(function (cell) {
                        // Check if the content of the cell is '0' or empty
                        if (cell.textContent.trim() === '0' || cell.textContent.trim() === '') {
                            // Set background color to white
                            cell.style.backgroundColor = 'white';
                        }
                    });
                });

                vm.isReportGenerated = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        vm.submitMap = function (str) {
            var payload = []

            if (str == 'terrain') {
                vm.mappedTypes.forEach(function (item) {
                    item.task.forEach(function (task) {
                        payload.push({
                            "taskType": {
                                "id": task.id
                            },
                            "tenant": {
                                "id": vm.trProject.tenantId
                            },
                            "terrainType": {
                                "id": item.terrain.id
                            },
                            "project": {
                                "id": vm.trProject.id
                            }
                        })
                    })

                })
                taskConfiguration.postTerrainConfiguration(payload, function () {
                    taskConfiguration.getTerrainConfiguration(vm.trProject.id, function (data) {
                        vm.existingMap = data

                        $scope.groupedData = {};

                        // Group data based on tree
                        data.forEach(function (item) {
                            var treeName = item.terrainType.name;
                            if (!$scope.groupedData[treeName]) {
                                $scope.groupedData[treeName] = [];
                            }
                            $scope.groupedData[treeName].push(item.taskType.name);
                        });
                    })
                    // $scope.setTab(8)
                    vm.mappedTypes = []
                    $('#selectedTerrain').val(null).trigger('change.select2');
                    vm.taskTypes.forEach(function (t) {
                        t.ticked = false
                    })
                })
            }

            if (str == 'row') {
                vm.mappedTypesRow.forEach(function (item) {
                    item.task.forEach(function (task) {
                        payload.push({
                            "taskType": {
                                "id": task.id
                            },
                            "tenant": {
                                "id": vm.trProject.tenantId
                            },
                            "rowType": {
                                "id": item.row.id
                            },
                            "project": {
                                "id": vm.trProject.id
                            }
                        })
                    })

                })
                taskConfiguration.postRoWConfiguration(payload, function () {
                    //$scope.setTab(8)

                    taskConfiguration.getRoWConfiguration(vm.trProject.id, function (data) {
                        vm.existingMapRow = data

                        $scope.groupedDataRow = {};

                        // Group data based on tree
                        data.forEach(function (item) {
                            var treeName = item.rowType.name;
                            if (!$scope.groupedDataRow[treeName]) {
                                $scope.groupedDataRow[treeName] = [];
                            }
                            $scope.groupedDataRow[treeName].push(item.taskType.name);
                        });
                    })
                    vm.mappedTypesRow = []
                    $('#selectRow').val(null).trigger('change.select2');


                    vm.taskTypesRow.forEach(function (t) {
                        t.selected = false
                    })
                })
            }

        }

        vm.rowChange = function () {
            vm.taskTypesRow.forEach(function (t) {
                t.selected = false
            })

            vm.mappedTypesRow.forEach(function (item) {
                if (item.row.id == vm.selectedRoW.id) {
                    item.task.forEach(function (e) {
                        vm.taskTypesRow.forEach(function (t) {
                            if (e.id == t.id) {
                                t.selected = true
                            }
                        })
                    })
                }
            })
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        locations.map = new google.maps.Map(
            document.getElementById("map_canvas"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: 13,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterEnd)
        );

        if (vm.trProject.latitude != null && vm.trProject.longitude != null) {
            locations.map.setCenter({ lat: vm.trProject.latitude, lng: vm.trProject.longitude });
            addMarker({ lat: vm.trProject.latitude, lng: vm.trProject.longitude })
        }

        function addMarker(location) {
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map
            });
        }
        //TrRouteListByProjectId.get(vm.trProject.id,function(data){})
        vm.page = 1;
        vm.predicate = 'modified_date';
        vm.itemsPerPage = 10;
        vm.mtrpage = 1;
        vm.mtrPerPage = 10;
        vm.materialTxnByDates = [];
        vm.materialByDate = [];
        vm.loadMaterialTxnDate = function () {
            MaterialDates.query({
                type: "project",
                id: entity.id,
                page: vm.mtrpage - 1,
                size: vm.mtrPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.TotalItems = headers('X-Total-Count');
                vm.QueryCount = vm.TotalItems;
                vm.materialByDate = data.map(function (item) {
                    return {
                        timestamp: item[0],
                        total: item[1],
                        isExpand: false
                    };
                });

            });
        };
        vm.loadMaterialTxnDate();
        vm.loadMaterialTxnByDates = function (date) {
            MaterialByDates.query({
                type: 'project',
                id: entity.id,
                date: date,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.materialTxnByDates = data.map(function (item) {
                    return {
                        transactionId: item[0],
                        timestamp: item[4],
                        transactionName: item[1],
                        transactionType: item[2],
                        count: item[3],
                        status: item[5],
                    };
                });
            });
        };
        vm.toggleExpand = function (selectedTask) {
            angular.forEach(vm.materialByDate, function (task) {
                if (task === selectedTask) {
                    task.isExpand = !task.isExpand;
                    if (task.isExpand) {
                        vm.loadMaterialTxnByDates(task.timestamp);
                    } else {
                        vm.materialTxnByDates = [];
                    }
                } else {
                    task.isExpand = false;
                }
            });
        };
        vm.transitionMainMateril = function () {
            vm.loadMaterialTxnDate()
        }

        

        vm.downloadSurveyReport = function () {
            BaseUrl.getBaseUrl(function (baseurl) {
                var url = "";
                url = baseurl.stringValue + "api/route-survey-report/" + vm.trProject.id + "/" + vm.trProject.name;
                var request = new XMLHttpRequest();
                request.open('GET', url, true);
                request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.authenticationToken);
                request.responseType = 'blob';

                request.onload = function (e) {
                    if (this.status === 200) {
                        var blob = this.response;
                        if (blob.size > 0) {
                            if (window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveBlob(blob, 'test.xlsx');
                            }
                            else {
                                var downloadLink = window.document.createElement('a');
                                var contentTypeHeader = request.getResponseHeader("Content-Type");
                                downloadLink.href = window.URL.createObjectURL(new Blob([blob], {
                                    type: contentTypeHeader
                                }));
                                downloadLink.download = 'Report.xlsx';

                                downloadLink.download = 'SurveyReport.xlsx';

                                document.body.appendChild(downloadLink);
                                downloadLink.click();
                                document.body.removeChild(downloadLink);
                            }
                        }
                    }else if(this.status == 404){alert("No Data Available")}
                };
                request.send();
            });
        }

    }
})();