(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrRouteDetailController', TrRouteDetailController);

    TrRouteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrRoute', 'User', 'RouteType', 'TrRouteImages', 'TrRouteRecordings', 'AlertService', '$window', '$localStorage', 'getRouteImage', 'Configs', 'routeDetail', 'Inventory', 'SummaryPost', 'imageRoutePoint', 'TrRouteForMap', 'TaskLogRouteReportData', 'MaterialByDates', 'MaterialDates', 'ParseLinks', 'RouteByID', 'Report', 'dailyLogByTaskId', 'RouteAssetsById', 'TaskSectionById'];

    function TrRouteDetailController($scope, $rootScope, $stateParams, previousState, entity, TrRoute, User, RouteType, TrRouteImages, TrRouteRecordings, AlertService, $window, $localStorage, getRouteImage, Configs, routeDetail, Inventory, SummaryPost, imageRoutePoint, TrRouteForMap, TaskLogRouteReportData, MaterialByDates, MaterialDates, ParseLinks, RouteByID, Report, dailyLogByTaskId, RouteAssetsById, TaskSectionById) {
        var vm = this;

        vm.trRoute = entity;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;
        vm.showTaskLogReport = showTaskLogReport;
        vm.downloadRouteKml = downloadRouteKml;
        var unsubscribe = $rootScope.$on('trakeyeApp:trRouteUpdate', function (event, result) {
            vm.trRoute = result;
        });
        vm.showRouteAttachment = false
        vm.expandResponse = []
        var colors = [];

        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;

        var uniqueColors = [
            "#FF0000", "#0000FF", "#008000", "#FFFF00", "#FFA500", "#800080", "#FFC0CB", "#00FFFF", "#FF00FF",
            "#008080", "#E6E6FA", "#A52A2A", "#800000", "#000080", "#808000", "#00FF00", "#FFDAB9", "#000000",
            "#808080", "#C0C0C0", "#FFD700", "#4B0082", "#EE82EE", "#40E0D0", "#D2B48C", "#FA8072", "#FF7F50",
            "#F0E68C", "#DC143C", "#7CFC00"
        ];
        var phasePolylines = []
        var phaseMarkers = []
        var coordinateListMaintenance = []
        var coordinateListRollout = []
        var coordinateListPlanSurvey = []
        var coordinateListPlanning = []
        var assetSpreadListPlanning = []
        var assetSpreadListMaintenance = []
        var assetSpreadListRollout = []
        var assetSpreadListPlanSurvey = []

        var colors = uniqueColors.map(function (color) {
            return { color: color, used: false };
        });

        Configs.get("RouteImage", function (response) {
            if (response.length > 0) {
                if (response[0].configValue == "True") {
                    vm.showRouteAttachment = true
                }
            }
        })

        vm.imageInNewTab = function (img) {
            var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' +
                img +
                '">'
            );
            imgWindow.document.close();
        };

        function downloadRouteKml() {
            Report.downloadRouteKml({
                id: vm.trRoute.id
            }, function (response) {
                var a = document.createElement("a");
                a.style = "display: none";
                var file = new Blob([(response)], { type: 'application/text' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL;
                a.download = vm.trRoute.name + ".kml";
                a.click();
                window.URL.revokeObjectURL(fileURL);
            });
        }

        /* $scope.newTabDisplay = function(id,imgid){
            var request = new XMLHttpRequest()
                
                request.open(
                        "GET",
                        'api/routepoint-images/'+id+'/'+imgid,
                        true
                );
                
                request.setRequestHeader(
                    "Authorization",
                    "Bearer " + $localStorage.authenticationToken
                );
                request.responseType = "blob";
                request.onload = response;
    
                function response(e) {
                    if (this.status === 200) {
                        var urlCreator = window.URL || window.webkitURL;
                        var imageUrl = urlCreator.createObjectURL(this.response);
                        window.open(imageUrl, "_blank");
                    }
                }
                request.send();
        }  */

        $scope.$on('$destroy', unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };

        $scope.allimages = false;
        //		$scope

        $scope.toggle = function (id) {
            switch (id) {
                case "image":
                    $scope.allimages = !$scope.allimages;
                    vm.showImages = !vm.showImages;
                    if ($scope.allimages) {
                        TrRouteImages.query(
                            {
                                routeId: vm.trRoute.id,
                            },
                            onSuccess,
                            onError
                        );

                        function onSuccess(data, headers) {
                            // console.log('Recived data-----------');

                            vm.routeImages = data.filter(function (routeImages) {
                                return routeImages.image
                            });

                        }

                        function onError(error) {
                            AlertService.error(error.data.message);
                        }
                    }
                    break;

                case "termination":
                    $scope.terminationDetails = !$scope.terminationDetails;
                    vm.showTermination = !vm.showTermination;
                    break;

                case "fms":
                    $scope.fmsDetails = !$scope.fmsDetails;
                    vm.showFMS = !vm.showFMS;
                    break;

                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                case "cable":
                    $scope.cableDetails = !$scope.cableDetails;
                    vm.showCable = !vm.showCable;
                    break;

                case "pdf":
                    $scope.allpdf = !$scope.allpdf;
                    vm.showPdf = !vm.showPdf;
                    if ($scope.allpdf) {
                        getRouteImage.get(vm.trRoute.id, function (res) {
                            vm.attachments = res;
                        });
                    }

                    break;

                default:
                    console.log("");
            }



			/*
			
			$scope.allimages = !$scope.allimages;
			
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/route_"+vm.trRoute.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			
			if ($scope.allimages) {
				TrRouteImages.query({
					routeId : vm.trRoute.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					// console.log('Recived data-----------');
					vm.routeImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		*/};

        //		$scope.playAudio = function() {
        ////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/route_"+vm.trRoute.id+".mp3";
        //            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/route_15570572.mp3";
        //	        var audio = new Audio(urlStr);
        //	        audio.play();
        //	    };


        function decodeImage(img) {
            return window.atob(img);
        }

        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };


        $scope.setTab = function (newTab) {

            if (newTab == 8) {
                //Comment Box
                vm.objectComments = {}
                vm.objectComments.entityId = entity.id
                vm.objectComments.entityName = "ROUTE"
            }

            if (newTab == 4 && vm.dailyLogsTxSummary.sectionTasks.length) {
                $scope.tab = 7
                showTaskLogReport(vm.trRoute.id);
            } else {
                $scope.tab = newTab;
            }
        }


        /*  routeDetail.getSection(entity.id,function(response){
             vm.sectionResponse = response
         })
          */
        routeDetail.getTask(entity.id, function (response) {
            vm.taskResponse = response
        })

        routeDetail.getRoutesDetails(entity.id, function (response) {
            vm.sectionResponse = response
        })

        var locations = {};
        var markers = [];
        var coordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 24
        var center = {
            lat: parseFloat($rootScope.DEFAULT_LATITUDE),
            lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
        };

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            myOption($rootScope.rolloutMapInitZoom, center)
        );

        function templateBg(color) {
            return '<img class="square" style="background-color:' +
                color +
                ';">'
        }

        vm.phaseFilter = []
        vm.blockFilter = []
        var tempColor = {
            "PLANNING": "#FD7A24",
            "PROPOSED": "#0096FF",
            "ROLLOUT": "#7FFF00",
            "MAINTENANCE": "#DE3163"
        }

        var isObjBool = {
            "PLANNING": false,
            "PROPOSED": false,
            "ROLLOUT": false,
            "MAINTENANCE": false
        }




        if (vm.trRoute && vm.trRoute.routePoints != null && vm.trRoute.routePoints.length) {
            vm.trRoute.routePoints.sort(function (a, b) { return a.routePointNumber - b.routePointNumber });
            vm.trRoute.routePoints.forEach(function (each) {

                if (each.routePointType == "COORDINATE" && each.phase != null) {

                    callSwitchPhaseCoordinate(each, each.phase)
                    isObjBool[each.phase] = true

                }/* else if(each.routePointType == "MARKER"){
                   markerList.push({lat:each.latitude,lng:each.longitude})
               } */else if (each.routePointType == "CHAINAGE" && each.phase != null) {
                    isObjBool[each.phase] = true
                    chainageList.push({ latitude: each.latitude, longitude: each.longitude, type: each.routePointType, number: each.routePointNumber, phase: each.phase })
                }
            })

            callPhaseCoordinate()

            /* if(markerList.length){
                locations.map.setCenter(markerList[0]);
                createMarkers(markerList,'content/images/markersPoint.png',locations.map,null)
            }  */

            if (chainageList.length) {

                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map, null)

            }

            callObjPhases()

        }

        function callObjPhases() {
            if (isObjBool["PLANNING"]) {
                vm.phaseFilter.push({ name: "PLANNING", selected: true, strokeColor: templateBg("#FD7A24") })
            }

            if (isObjBool["PROPOSED"]) {
                vm.phaseFilter.push({ name: "PROPOSED", selected: true, strokeColor: templateBg("#0096FF") })

            }

            if (isObjBool["ROLLOUT"]) {
                vm.phaseFilter.push({ name: "ROLLOUT", selected: true, strokeColor: templateBg("#7FFF00"), color: "#7FFF00" })

            }

            if (isObjBool["MAINTENANCE"]) {
                vm.phaseFilter.push({ name: "MAINTENANCE", selected: true, strokeColor: templateBg("#DE3163"), color: "#DE3163" })

            }
        }

        function callPhaseCoordinate() {
            if (coordinateListPlanning.length) {

                locations.map.setCenter(coordinateListPlanning[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListPlanning,
                    geodesic: true,
                    strokeColor: tempColor["PLANNING"],
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                    name: "PLANNING"
                });

                coordinateListPlanning.forEach(function (each) {
                    createInfoWindow(polyline, each)
                })

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (coordinateListPlanSurvey.length) {
                //vm.phaseFilter.push({name:"PROPOSED",selected:true,strokeColor:templateBg("#0096FF")})

                locations.map.setCenter(coordinateListPlanSurvey[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListPlanSurvey,
                    geodesic: true,
                    strokeColor: tempColor["PROPOSED"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "PROPOSED"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (coordinateListRollout.length) {
                //vm.phaseFilter.push({name:"ROLLOUT",selected:true,strokeColor:templateBg("#7FFF00"), color:"#7FFF00"})

                locations.map.setCenter(coordinateListRollout[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListRollout,
                    geodesic: true,
                    strokeColor: tempColor["ROLLOUT"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "ROLLOUT"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (coordinateListMaintenance.length) {
                //vm.phaseFilter.push({name:"MAINTENANCE",selected:true,strokeColor:templateBg("#DE3163"), color :"#DE3163"})

                locations.map.setCenter(coordinateListMaintenance[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListMaintenance,
                    geodesic: true,
                    strokeColor: tempColor["MAINTENANCE"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "MAINTENANCE"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }
        }
        function callPhaseSpread() {
            if (assetSpreadListPlanning.length) {
                //vm.phaseFilter.push({name:"PLANNING",selected:true,strokeColor:templateBg("#FD7A24")})
                locations.map.setCenter(assetSpreadListPlanning[0]);

                var polyline = new google.maps.Polyline({
                    path: assetSpreadListPlanning,
                    geodesic: true,
                    strokeColor: tempColor["PLANNING"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "PLANNING"
                });

                // Set the map to display the polyline
                assetSpreadListPlanning.forEach(function (each) {
                    createInfoWindow(polyline, each)
                    polyline.set('block', each.block);
                })
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }


            if (assetSpreadListPlanSurvey.length) {
                //vm.phaseFilter.push({name:"PROPOSED",selected:true,strokeColor:templateBg("#0096FF")})

                locations.map.setCenter(assetSpreadListPlanSurvey[0]);
                var polyline = new google.maps.Polyline({
                    path: assetSpreadListPlanSurvey,
                    geodesic: true,
                    strokeColor: tempColor["PROPOSED"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "PROPOSED"
                });

                assetSpreadListPlanSurvey.forEach(function (each) {
                    createInfoWindow(polyline, each)
                    polyline.set('block', each.block);
                })
                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (assetSpreadListRollout.length) {
                //vm.phaseFilter.push({name:"ROLLOUT",selected:true,strokeColor:templateBg("#7FFF00"), color:"#7FFF00"})

                locations.map.setCenter(assetSpreadListRollout[0]);
                var polyline = new google.maps.Polyline({
                    path: assetSpreadListRollout,
                    geodesic: true,
                    strokeColor: tempColor["ROLLOUT"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "ROLLOUT"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (assetSpreadListMaintenance.length) {
                //vm.phaseFilter.push({name:"MAINTENANCE",selected:true,strokeColor:templateBg("#DE3163"), color :"#DE3163"})

                locations.map.setCenter(assetSpreadListMaintenance[0]);
                var polyline = new google.maps.Polyline({
                    path: assetSpreadListMaintenance,
                    geodesic: true,
                    strokeColor: tempColor["MAINTENANCE"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "MAINTENANCE"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }
        }

        function callSwitchPhaseCoordinate(each, ph) {
            switch (ph) {
                case "PLANNING":
                    coordinateListPlanning.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;

                case "PROPOSED":
                    coordinateListPlanSurvey.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;

                case "ROLLOUT":
                    coordinateListRollout.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;

                case "MAINTENANCE":
                    coordinateListMaintenance.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;
            }
        }

        function callSwitchPhaseSpread(each, ph, name, block) {
            switch (ph) {
                case "PLANNING":
                    assetSpreadListPlanning.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, name: name, block: block })
                    break;

                case "PROPOSED":
                    assetSpreadListPlanSurvey.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, name: name, block: block })
                    break;

                case "ROLLOUT":
                    assetSpreadListRollout.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, block: block })
                    break;

                case "MAINTENANCE":
                    assetSpreadListMaintenance.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, block: block })
                    break;
            }
        }

        $scope.togglePhase = function () {
            if (vm.selectedPhase.length) {
                clearPhase()
                vm.selectedPhase.forEach(function (each) {
                    for (var i = 0; i < phasePolylines.length; i++) {
                        if (phasePolylines[i].name === each.name) {
                            if (each.selected) {
                                phasePolylines[i].setMap(locations.map);
                            }
                        }
                    }
                    for (var i = 0; i < phaseMarkers.length; i++) {
                        if (phaseMarkers[i].name === each.name) {
                            if (each.selected) {
                                phaseMarkers[i].setMap(locations.map);
                            }
                        }
                    }
                })
            } else {
                clearPhase()
            }
        };

        $scope.toggleBlock = function () {
            if (vm.selectedBlock.length) {
                clearPhase()
                vm.selectedBlock.forEach(function (each) {
                    for (var i = 0; i < phasePolylines.length; i++) {
                        if (phasePolylines[i].block === each.name /* || phasePolylines[i].block == null */) {
                            if (each.selected) {
                                phasePolylines[i].setMap(locations.map);
                            }
                        }
                    }
                    for (var i = 0; i < phaseMarkers.length; i++) {
                        if (phaseMarkers[i].block === each.name /* || phasePolylines[i].block == null */) {
                            if (each.selected) {
                                phaseMarkers[i].setMap(locations.map);
                            }
                        }
                    }
                })
            } else {
                clearPhase()
                vm.phaseFilter.forEach(function (item) {
                    item.selected = false
                })
            }
        };

        function createMarkers(arr, img, map, str) {
            var marker, markerSymbol
            arr.forEach(function (each) {

                marker = new google.maps.Marker({
                    position: { lat: each.latitude, lng: each.longitude },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                    name: each.phase
                });

                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        console.log(each);
                        infowindow.setContent(
                            '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " + each.latitude + "</b></br>" +
                            "<b>Longitude : " + each.longitude + "</b></br>" +
                            "<b>Type : " + each.type + "</b></br>" +
                            "<b>Number : " + each.number + "</b></br>" +
                            "</br></div>"
                        );
                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.latitude,
                            lng: each.longitude,
                        });
                        openedInfoWindow = infowindow;
                    }))

                /*   markerSymbol = new google.maps.Marker({
                     icon:  {
                         path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                         strokeColor: tempColor[each.phase],
                         fillColor: tempColor[each.phase],
                         fillOpacity:1,
                         scale: 1,
                         labelOrigin: new google.maps.Point(-1, 7)
                     },
                     map: locations.map,
                     position:  { lat: each.latitude, lng: each.longitude },
                     name:each.phase
                 });  */
                phaseMarkers.push(marker);
                //phaseMarkers.push(markerSymbol);


            })
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
            {
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#EFEFEF",
                    },
                ],
            }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        /* vm.expand = function(section,str) {
            vm.sectionResponse.forEach(function (item) {
                item.material = false;
                item.tasks = false;
                item.resource = false;
            });
            
            if (vm.toggle == section.id) {
                vm.toggle = undefined;
            } else {
                switch(str){
                    case "material":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.materialTxn(section.id,function(response){
                            vm.headers = [ 
                            { key: "materialName", display: "Material Name" },
                            { key: "noOfUnits", display: "No of Units" },
                            { key: "transactionType", display: "Transaction Type" },
                            { key: "transactionDate", display: "Transaction Date" },
                        ]
                            vm.expandResponse = response
                        }) 
                        section.material = true;
                        vm.toggle = section.id;
                        break;
        
                    case "resource":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.resourceTxn(section.id,function(response){
                            vm.headers = [ 
                                { key: "resourceName", display: "Resource Name" },
                                { key: "noOfResources", display: "No of Resource" },
                                { key: "transactionType", display: "Transaction Type" },
                                { key: "transactionDate", display: "Transaction Date" },
                            ]
                            vm.expandResponse = response
                        })
                        section.resource = true;
                        vm.toggle = section.id;
                        break;
        
                    case "tasks":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.taskBySection(section.id,function(response){
                            vm.headers = [ 
                                { key: "name", display: "Name" },
                                { key: "taskType", display: "Task Type" }
                            ]
                            vm.expandResponse = response
                        })
                        section.tasks = true;
                        vm.toggle = section.id;
                         break; 
                    
                    default : console.log("default")
                   }
             }
        } */


        vm.expand = function (section, str) {
            vm.sectionResponse.forEach(function (item) {
                item.tasks = false;
            });

            if (vm.toggle == section.sectionId) {
                vm.toggle = undefined;
            } else {
                switch (str) {
                    case "tasks":
                        vm.expandResponse = null
                        vm.headers = null
                        routeDetail.postSectionsDetails({ "sectionIds": [section.sectionId] }, function (response) {
                            vm.headers = [
                                { key: "logs", display: "Logs" },
                                { key: "taskName", display: "Task Name" },
                                { key: "userLogin", display: "Assigned To" }
                            ]
                            vm.expandResponse = response
                        })
                        section.tasks = true;
                        vm.toggle = section.sectionId;
                        break;

                    default: console.log("default")
                }
            }
        }

        SummaryPost.dailyLogsTx({
            "projectIds": [vm.trRoute.projectId],
            "routeIds": [vm.trRoute.id]
        }, function (response) {
            vm.dailyLogsTxSummary = response
        })

        SummaryPost.mtrlTx({
            "projectIds": [vm.trRoute.projectId],
            "routeIds": [vm.trRoute.id]
        }, function (response) {
            vm.mtrlTxSummary = response
        })

        SummaryPost.resTx({
            "projectIds": [vm.trRoute.projectId],
            "routeIds": [vm.trRoute.id]
        }, function (response) {
            vm.resTxSummary = response
        })

        vm.objectRouteImg = {}
        vm.objectRouteImg.routeId = vm.trRoute.id
        vm.objectRouteImg.map = locations.map

        vm.mapFilterList = [];
        var polylines = []
        var tempPath = []

        TrRouteForMap.getTerrainPathByRoute(
            vm.trRoute.id,
            function (response) {
                vm.terrainPathResponse = response

                callTerrainPath()

            }, function (err) {
                vm.mapFilterList.push({
                    name:
                        '<strong>' + vm.trRoute.name + '</strong>',
                    path: coordinateList,
                    visible: true,
                    strokeColor: '<img class="square" style="background-color:#' +
                        'FD7A24' +
                        ';">',
                    colorCode: 'FD7A24',
                    routeDisable: true
                })

                for (var i = 0; i < vm.mapFilterList.length; i++) {
                    var line = new google.maps.Polyline({
                        path: vm.mapFilterList[i].path,
                        visible: vm.mapFilterList[i].visible,
                        strokeColor: '#' + vm.mapFilterList[i].colorCode,
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                        name: vm.mapFilterList[i].name
                    });
                    line.setMap(locations.map);
                    polylines.push(line);
                }

                TrRouteForMap.getRoWPathByRoute(
                    vm.trRoute.id,
                    function (response) {
                        vm.rowPathResponse = response
                        if (response.length) {
                            vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                            callRowPath()
                        }

                    }
                );
            }
        );

        function callTerrainPath() {

            TrRouteForMap.getTerrainByRoute(
                vm.trRoute.id,
                function (nameResponse) {
                    polylines = []
                    vm.terrainListResponse = nameResponse
                    if (nameResponse.length) {
                        vm.isShowFilter = true
                        vm.mapFilterList.push({
                            name:
                                '<strong>' + vm.trRoute.name + '</strong>',
                            path: coordinateList,
                            visible: true,
                            strokeColor: '<img class="square" style="background-color:#' +
                                'FD7A24' +
                                ';">',
                            colorCode: 'FD7A24',
                            routeDisable: true,

                        })
                        vm.mapFilterList.push({ name: '<strong>Terrain</strong>', msGroup: true })
                        nameResponse.forEach(function (nameEach, indexName) {
                            if (vm.terrainPathResponse.length) {
                                vm.terrainPathResponse.forEach(function (item, indexPath) {
                                    if (nameEach.id == item.id) {
                                        if (
                                            item.value["COORDINATE"]
                                        ) {
                                            item.value["COORDINATE"].forEach(function (cord) {
                                                tempPath.push({ lat: cord.latitude, lng: cord.longitude })
                                            })

                                            var colorCode = randomColor()
                                            vm.mapFilterList.push({
                                                name:
                                                    nameEach.name,
                                                path: tempPath,
                                                visible: true,
                                                strokeColor: '<img class="square" style="background-color:' +
                                                    colorCode +
                                                    ';">',
                                                colorCode: colorCode
                                            });
                                            tempPath = []
                                        }
                                    }
                                });
                            }

                            // for (var i = 0; i < vm.mapFilterList.length; i++) {
                            //     var line = new google.maps.Polyline({
                            //         path: vm.mapFilterList[i].path,
                            //         visible: vm.mapFilterList[i].visible,
                            //         strokeColor: '#' + vm.mapFilterList[i].colorCode,
                            //         strokeOpacity: 1.0,
                            //         strokeWeight: 4,
                            //         name: vm.mapFilterList[i].name
                            //     });
                            //     line.setMap(locations.map);
                            //     polylines.push(line);
                            // }
                        });
                        vm.mapFilterList.push({ msGroup: false })
                        TrRouteForMap.getRoWPathByRoute(
                            vm.trRoute.id,
                            function (response) {
                                vm.rowPathResponse = response
                                // if (response.length) {
                                //     vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                callRowPath()
                                // }

                            }
                        );
                    } else {
                        vm.mapFilterList.push({
                            name:
                                '<strong>' + vm.trRoute.name + '</strong>',
                            path: coordinateList,
                            visible: true,
                            strokeColor: '<img class="square" style="background-color:#' +
                                'FD7A24' +
                                ';">',
                            colorCode: 'FD7A24',
                            routeDisable: true
                        })

                        for (var i = 0; i < vm.mapFilterList.length; i++) {
                            var line = new google.maps.Polyline({
                                path: vm.mapFilterList[i].path,
                                visible: vm.mapFilterList[i].visible,
                                strokeColor: '#' + vm.mapFilterList[i].colorCode,
                                strokeOpacity: 1.0,
                                strokeWeight: 4,
                                name: vm.mapFilterList[i].name
                            });
                            line.setMap(locations.map);
                            polylines.push(line);
                        }

                        TrRouteForMap.getRoWPathByRoute(
                            vm.trRoute.id,
                            function (response) {
                                vm.rowPathResponse = response
                                if (response.length) {
                                    vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                    callRowPath()
                                }

                            }
                        );
                    }
                },
                function (error) {
                    vm.mapFilterList.push({
                        name:
                            '<strong>' + vm.trRoute.name + '</strong>',
                        path: coordinateList,
                        visible: true,
                        strokeColor: '<img class="square" style="background-color:#' +
                            'FD7A24' +
                            ';">',
                        colorCode: 'FD7A24',
                        routeDisable: true
                    })

                    for (var i = 0; i < vm.mapFilterList.length; i++) {
                        var line = new google.maps.Polyline({
                            path: vm.mapFilterList[i].path,
                            visible: vm.mapFilterList[i].visible,
                            strokeColor: '#' + vm.mapFilterList[i].colorCode,
                            strokeOpacity: 1.0,
                            strokeWeight: 4,
                            name: vm.mapFilterList[i].name
                        });
                        line.setMap(locations.map);
                        // polylines.push(line);
                    }

                    TrRouteForMap.getRoWPathByRoute(
                        vm.trRoute.id,
                        function (response) {
                            vm.rowPathResponse = response
                            if (response.length) {
                                vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                callRowPath()
                            }

                        }
                    );
                }
            );
        }

        function callRowPath() {

            TrRouteForMap.getRoWByRoute(
                vm.trRoute.id,
                function (nameResponse) {
                    vm.rowListResponse = nameResponse
                    if (nameResponse.length) {
                        nameResponse.forEach(function (nameEach, indexName) {
                            if (vm.rowPathResponse.length) {
                                vm.rowPathResponse.forEach(function (item, indexPath) {
                                    if (nameEach.id == item.id) {
                                        if (
                                            item.value["COORDINATE"]
                                        ) {
                                            item.value["COORDINATE"].forEach(function (cord) {
                                                tempPath.push({ lat: cord.latitude, lng: cord.longitude })
                                            })

                                            var colorCode = randomColor()
                                            vm.mapFilterList.push({
                                                name:
                                                    nameEach.name,
                                                path: tempPath,
                                                visible: true,
                                                strokeColor: '<img class="square" style="background-color:' +
                                                    colorCode +
                                                    ';">',
                                                colorCode: colorCode
                                            });
                                            tempPath = []
                                        }
                                    }
                                });
                            }

                            // for (var i = 0; i < vm.mapFilterList.length; i++) {
                            //     var line = new google.maps.Polyline({
                            //         path: vm.mapFilterList[i].path,
                            //         visible: vm.mapFilterList[i].visible,
                            //         strokeColor: '#' + vm.mapFilterList[i].colorCode,
                            //         strokeOpacity: 1.0,
                            //         strokeWeight: 4,
                            //         name: vm.mapFilterList[i].name
                            //     });
                            //     line.setMap(locations.map);
                            //     polylines.push(line);
                            // }
                        });
                        vm.mapFilterList.push({ msGroup: false })
                    }
                }
            );
        }
        $scope.togglePolyline = function () {
            if (vm.selectedTerrainName.length) {
                clearPolyLines()
                vm.selectedTerrainName.forEach(function (each) {
                    for (var i = 0; i < polylines.length; i++) {
                        if (polylines[i].name === each.name) {
                            if (each.visible) {
                                polylines[i].setMap(locations.map);
                            }
                        } else {
                            polylines[i].setMap(null)
                        }
                    }
                })
            } else {
                clearPolyLines()
            }
        };


        function clearPolyLines() {
            for (var i = 0; i < polylines.length; i++) {

                polylines[i].setMap(null);

            }
        }


        /*  vm.closeModal = function(id) {
          vm.nameImages = null
          $("#" + id).removeClass("in");
          $(".modal-backdrop").remove();
          $("#" + id).hide();
      } */

        $scope.type = {
            terrain: {
                search: "Search",
                nothingSelected: "Map Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            },
            phase: {
                search: "Search",
                nothingSelected: "Phase Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            },
            block: {
                search: "Search",
                nothingSelected: "Block Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            }
        };

        function randomColor() {
            var unusedColors = colors.filter(function (color) {
                return !color.used;
            });
            if (unusedColors.length === 0) return null;
            var randomIndex = Math.floor(Math.random() * unusedColors.length);
            var randomColor = unusedColors[randomIndex];
            randomColor.used = true;
            return randomColor.color;
        }

        function createMetaTag(attrName, attrValue, content) {
            var meta = document.createElement('meta');
            meta.setAttribute(attrName, attrValue);
            meta.setAttribute('content', content);
            return meta;
        }

        function createTable(classNames) {
            var table = document.createElement('table');
            table.setAttribute('border', '1');
            table.setAttribute('cellpadding', '5');
            table.setAttribute('cellspacing', '0');
            table.setAttribute('id', 'sheet0');
            table.setAttribute('class', classNames);
            return table;
        }

        function createTableCell(tagName, textContent) {
            var cell = document.createElement(tagName);
            cell.textContent = textContent;
            return cell;
        }

        function showTaskLogReport(routeId) {
            vm.isReportGenerated = false;
            TaskLogRouteReportData.query(
                {
                    reportType: "daily",
                    routeId: routeId
                },
                onSuccess,
                onError
            );

            function onSuccess(data) {
                var htmlDoc = document.createElement('html');
                var head = document.createElement('head');
                var meta1 = createMetaTag('http-equiv', 'Content-Type', 'text/html; charset=utf-8');
                var meta2 = createMetaTag('name', 'generator', 'PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet');
                var meta3 = createMetaTag('name', 'author', 'Apache POI');
                var meta4 = createMetaTag('name', 'company', 'Microsoft Corporation');
                head.appendChild(meta1);
                head.appendChild(meta2);
                head.appendChild(meta3);
                head.appendChild(meta4);
                var body = document.createElement('body');
                var table = createTable('sheet0 gridlines');
                var thead = document.createElement('thead');
                var tbody = document.createElement('tbody');

                function createTable(classNames) {
                    var table = document.createElement('table');
                    table.setAttribute('border', '1');
                    table.setAttribute('cellpadding', '5');
                    table.setAttribute('cellspacing', '0');
                    table.setAttribute('id', 'sheet0');
                    table.setAttribute('class', classNames);
                    return table;
                }

                function createMetaTag(attrName, attrValue, content) {
                    var meta = document.createElement('meta');
                    meta.setAttribute(attrName, attrValue);
                    meta.setAttribute('content', content);
                    return meta;
                }

                function createTableCell(tag, content) {
                    var cell = document.createElement(tag);
                    cell.textContent = content;
                    cell.style.fontSize = "13px";
                    return cell;
                }

                thead.className = 'thReport';
                table.appendChild(thead);
                tbody.className = 'tdReport';
                table.appendChild(tbody);
                var styleElement = document.createElement('style');
                styleElement.textContent = "\
                .subheader {\
                    margin: auto;\
                }\
                .thReport > tr > th{\
                    text-align: center;\
                    padding: 10px;\
                }\
                .tdReport > tr > td {\
                    text-align: center; \
                    padding: 10px;\
                }\
                ";
                document.head.appendChild(styleElement);

                var colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2',];
                function getRandomColor() {
                    if (colors.length === 0) {
                        colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2'];
                    }
                    var index = Math.floor(Math.random() * colors.length);
                    var color = colors[index];
                    colors.splice(index, 1);
                    return color;
                }


                var groupedData = {};
                data.forEach(function (item) {
                    if (!groupedData[item.date]) {
                        groupedData[item.date] = [];
                    }
                    groupedData[item.date].push(item);
                });
                var names = getUniqueNames(data);
                var nameHeaderRow = document.createElement('tr');
                var serialNumberHeader = createTableCell('th', 'S.No');
                serialNumberHeader.style.fontWeight = 'bold';
                serialNumberHeader.style.backgroundColor = '#7dcfb6';
                serialNumberHeader.rowSpan = 2;
                nameHeaderRow.appendChild(serialNumberHeader);
                var dateHeader = createTableCell('th', 'Date');
                dateHeader.style.backgroundColor = '#7dcfb6';
                dateHeader.style.fontWeight = 'bold';
                dateHeader.rowSpan = 2;
                nameHeaderRow.appendChild(dateHeader);
                var typeHeaderRow = document.createElement('tr');

                names.forEach(function (name, index) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    var availableTypes = getUniqueTypes(nameData);
                    var nameHeaderCell = createTableCell('th', name);
                    nameHeaderCell.style.backgroundColor = getRandomColor();
                    nameHeaderCell.style.fontWeight = 'bold';
                    nameHeaderCell.colSpan = availableTypes.length;
                    nameHeaderRow.appendChild(nameHeaderCell);
                    availableTypes.forEach(function (type) {
                        var typeHeaderCell = createTableCell('th', type);
                        typeHeaderCell.style.backgroundColor = nameHeaderCell.style.backgroundColor;
                        typeHeaderCell.style.fontWeight = 'bold';
                        typeHeaderRow.appendChild(typeHeaderCell);
                    });
                });
                var totalProgressHeader = createTableCell('th', 'Total Progress');
                totalProgressHeader.style.backgroundColor = '#cfe0c3';
                totalProgressHeader.style.fontWeight = 'bold';
                totalProgressHeader.colSpan = 3;
                nameHeaderRow.appendChild(totalProgressHeader);
                var subHeaders = [
                    {
                        type: 'Total',
                    },
                    {
                        type: "Ducting",
                    }, {
                        type: "Validation",

                    }
                ];
                subHeaders.forEach(function (subHeader) {
                    var subHeaderCell = createTableCell('th', subHeader.type);
                    subHeaderCell.style.backgroundColor = '#cfe0c3';
                    subHeaderCell.style.fontWeight = 'bold';
                    typeHeaderRow.appendChild(subHeaderCell);
                });
                thead.appendChild(nameHeaderRow);
                thead.appendChild(typeHeaderRow);
                var totals = {};

                Object.keys(groupedData)
                    .sort(function (a, b) {
                        var dateA = a.split('-').reverse().join('-');
                        var dateB = b.split('-').reverse().join('-');
                        return new Date(dateA) - new Date(dateB);
                    })
                    .forEach(function (date, index) {
                        var totalLength = 0;
                        var totalValidation = 0;
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name && item.date === date; });
                            var ductingLength = 0;
                            var hddLength = 0;
                            var horizontalDirectionalDrilling = 0
                            var validationLength = 0;
                            nameData.forEach(function (item) {
                                if (item.type === 'Ducting') {
                                    ductingLength += item.length;
                                }
                                else if (item.type === 'HDD') {
                                    hddLength += item.length;

                                }
                                else if (item.type === 'HDD ( Horizontal Directional Drilling )') {
                                    horizontalDirectionalDrilling += item.length;
                                    console.log(horizontalDirectionalDrilling)
                                }
                                else if (item.type === 'Validation' || item.type === 'Validation (T&D)') {
                                    validationLength += item.length;
                                }
                            });
                            console.log(totalLength)
                            totalLength += (ductingLength + hddLength + horizontalDirectionalDrilling);
                            totalValidation += validationLength;

                        });
                        var tr = document.createElement('tr');
                        var serialNumberCell = createTableCell('td', index + 1);
                        serialNumberCell.style.fontSize = "13px";
                        tr.appendChild(serialNumberCell);
                        var dateCell = createTableCell('td', date);
                        dateCell.style.width = '77px'
                        dateCell.style.whiteSpace = 'nowrap';
                        dateCell.style.fontSize = "13px";
                        tr.appendChild(dateCell);
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name; });
                            var availableTypes = getUniqueTypes(nameData);

                            availableTypes.forEach(function (type) {
                                var item = groupedData[date].find(function (item) {
                                    return item.name === name && item.type === type;
                                });
                                var lengthValue = item ? item.length : 0;
                                var td = createTableCell('td', lengthValue);

                                tr.appendChild(td);
                                if (!totals[type]) {
                                    totals[type] = 0;
                                }
                                totals[type] += lengthValue;
                            });
                        });
                        var total = 0;
                        for (var i = 2; i < tr.cells.length; i++) {
                            total += parseInt(tr.cells[i].textContent);
                        }
                        var columnValueCell = createTableCell('td', total);
                        columnValueCell.colSpan = 1;
                        tr.appendChild(columnValueCell);
                        var remainingDuctingCell = createTableCell('td', totalLength);
                        tr.appendChild(remainingDuctingCell);
                        var remainingValidationCell = createTableCell('td', totalValidation);
                        tr.appendChild(remainingValidationCell);
                        if (index === 0) {
                            totals['Total Progress Ducting'] = 0;
                            totals['Total Progress Validation'] = 0;
                        }
                        totals['Total Progress Ducting'] += totalLength;
                        totals['Total Progress Validation'] += totalValidation;
                        tbody.appendChild(tr);
                    });

                var staticColumns = ['Total', 'Balance Validation', 'Balance Ducting', 'Material Issued', 'Wastage', 'Stock at Site'];
                var allAvailableTypes = [];
                names.forEach(function (name) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    allAvailableTypes.push.apply(allAvailableTypes, getUniqueTypes(nameData));
                });
                var availableTypes = allAvailableTypes;
                var maxUniqueTypesCount = availableTypes.length;
                var totalLength = maxUniqueTypesCount + subHeaders.length;

                var columnTotals = {};
                for (var i = 2; i < totalLength; i++) {
                    var total = 0;
                    for (var j = 0; j < tbody.rows.length; j++) {
                        var cellValue = parseInt(tbody.rows[j].cells[i].textContent) || 0;
                        total += cellValue;
                    }
                    columnTotals[i] = total;
                }
                staticColumns.forEach(function (column) {
                    var staticRow = document.createElement('tr');
                    var columnNameCell = createTableCell('td', column);
                    columnNameCell.colSpan = 2;
                    columnNameCell.style.backgroundColor = '#cfe0c3';
                    columnNameCell.style.fontSize = "13px";
                    columnNameCell.style.fontWeight = 'bold';
                    staticRow.appendChild(columnNameCell);
                    if (column == 'Total') {
                        for (var i = 2; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', columnTotals[i].toString());
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                        var columnValueCell = createTableCell('td', totals['Total Progress Ducting']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                        var columnValueCell = createTableCell('td', totals['Total Progress Validation']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                    } else {
                        for (var i = 0; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', '0');
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                    }
                    tbody.appendChild(staticRow);
                });
                table.appendChild(thead);
                table.appendChild(tbody);
                body.appendChild(table);
                htmlDoc.appendChild(body);
                htmlDoc.appendChild(head);
                var htmlString = htmlDoc.outerHTML;
                var containerDiv = document.getElementById("generatedHtmlContainer");
                containerDiv.innerHTML = htmlString;

                function getUniqueNames(data) {
                    var uniqueNames = [];
                    data.forEach(function (item) {
                        if (uniqueNames.indexOf(item.name) === -1) {
                            uniqueNames.push(item.name);
                        }
                    });
                    return uniqueNames;
                }
                function getUniqueTypes(data) {
                    var uniqueTypes = [];
                    data.forEach(function (item) {
                        if (uniqueTypes.indexOf(item.type) === -1) {
                            uniqueTypes.push(item.type);
                        }
                    });
                    return uniqueTypes;
                }

                // Set background color for specified cells
                var jcbCells = document.querySelectorAll('.row1 .column0'); // Select cells in row 1, column 0 (JCB)
                var hddCells = document.querySelectorAll('.row1 .column1'); // Select cells in row 1, column 1 (HDD)

                // Loop through JCB cells and set background color to light green
                jcbCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Loop through HDD cells and set background color to light green
                hddCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Get the Total row
                // Select all relevant rows
                var relevantRows = ['.row2', '.row3', '.row5', '.row6'];

                // Loop through each relevant row
                relevantRows.forEach(function (rowClass) {
                    var cells = document.querySelectorAll(rowClass + ' td');

                    // Loop through the cells in the row
                    cells.forEach(function (cell) {
                        // Check if the content of the cell is '0' or empty
                        if (cell.textContent.trim() === '0' || cell.textContent.trim() === '') {
                            // Set background color to white
                            cell.style.backgroundColor = 'white';
                        }
                    });
                });

                vm.isReportGenerated = true;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        function clearPhase() {
            for (var k = 0; k < phasePolylines.length; k++) {
                phasePolylines[k].setMap(null);
            }
            for (var j = 0; j < phaseMarkers.length; j++) {
                phaseMarkers[j].setMap(null);
            }
        }


        function createInfoWindow(polyline, each) {
            google.maps.event.addListener(
                polyline,
                "click",
                (function () {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                        /* if (spreadPoly != null) {
                            spreadPoly.setMap(null);
                        } */
                    }

                    /*   clearTimeout(polylineTimer);
  
                      spreadPoly = new google.maps.Polyline({
                          path: {
                              lat: each.lat,
                              lng: each.lng,
                          },
                          strokeColor: "#FFFF00",
                          strokeOpacity: 0.6,
                          strokeWeight: 19,
                      });
  
                      spreadPoly.setMap(locations.map);
  
                      polylineTimer = setTimeout(function () {
                          if (spreadPoly != null) {
                              spreadPoly.setMap(null);
                              spreadPoly = null;
                          }
                      }, 5000); */

                    var name = each.name ? "<b>Name : " + each.name + "</b></br>" : ""
                    var block = each.block ? "<b>Block : " + each.block + "</b></br>" : ""
                    /*  if (openedInfoWindow != null) {
                         openedInfoWindow.close();
                     } */


                    infowindow.setContent(
                        '<div class="infoWindowhead"></br></br>' +
                        name +
                        "<b>Latitude : " + each.lat + "</b></br>" +
                        "<b>Longitude : " + each.lng + "</b></br>" +
                        block +
                        "</br></div>"
                    );


                    infowindow.open(locations.map);
                    infowindow.setPosition({
                        lat: each.lat,
                        lng: each.lng,
                    });
                    openedInfoWindow = infowindow;
                }))
        }

        //callAssetsRouteByID(vm.trRoute.id,null)
        function callAssetsRouteByID(routeId, lastId) {
            assetsByRouteId.get(routeId, lastId, function (data) {
                if (data.assets.length) {
                    /*  data.assets.forEach(function(item){
                         if(vm.storeAssetTypes.length){
                             vm.storeAssetTypes.filter(function (eachElement) {
                                 if(item.assetTypeName == eachElement.name){
                                     item.assetTypeAttributeValues = eachElement.assetTypeAttributes
                                 }
                             });
                         }
                        
                     }) */
                    var markerSymbol, marker
                    data.assets.forEach(function (item) {
                        if (item.block) {
                            var isDuplicate = false;
                            for (var i = 0; i < vm.blockFilter.length; i++) {
                                if (vm.blockFilter[i].name === item.block) {
                                    isDuplicate = true;
                                    break;
                                }
                            }
                            if (!isDuplicate) {
                                vm.blockFilter.push({ name: item.block, selected: true });
                            }
                        }
                        if (item.assetCoordinates.length) {
                            if (item.assetTypeLayout == "FIXED" && item.phase != null) {
                                isObjBool[item.phase] = true
                                locations.map.setCenter({ lat: item.assetCoordinates[0].latitude, lng: item.assetCoordinates[0].longitude, });
                                var imagePath = item.assetTypeImagePath;
                                /*  if(item.assetTypeName == "GP"){
                                     item.assetTypeAttributeValues.forEach(function (value, index) {
                                         if (value.name == "GP Status") {
                                             if(value.attributeValue == "Connected"){
                                                 imagePath = 'content/images/panchayat_yellow.png';
                                             } else {
                                                 imagePath = 'content/images/panchayat_red.png';
                                             }
                                         }
                                     });
                                 } */

                                item.assetCoordinates.forEach(function (each, index) {



                                    marker = new google.maps.Marker({
                                        position: { lat: each.latitude, lng: each.longitude },
                                        map: locations.map,
                                        icon: {
                                            scaledSize: new google.maps.Size(iconSize, iconSize),
                                            url: imagePath,
                                            anchor: new google.maps.Point(
                                                iconSize / 2,
                                                iconSize / 2
                                            ),
                                            labelOrigin: new google.maps.Point(25, 22),
                                        },
                                        name: item.phase,
                                        block: item.block
                                    });


                                    google.maps.event.addListener(
                                        marker,
                                        "click",
                                        (function (marker) {
                                            var assetDetails = item;
                                            var userContent = document.createElement("div"),
                                                btnShowAttributes,
                                                btnCr,
                                                btnText,
                                                btnEditAsset,
                                                btnCreateCase,
                                                btnThreePointRef,
                                                brBreak;
                                            var otdr = "";
                                            var infoStr = "";
                                            /*  var assetTypeAttributeValuesList =
                                             assetDetails.assetTypeAttributeValues;
                                             assetTypeAttributeValuesList.sort(function (a, b) {
                                                 var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                                     nameB = a.assetTypeAttribute.name.toLowerCase();
                                                 if (nameA < nameB)
                                                     // sort string ascending
                                                     return -1;
                                                 if (nameA > nameB) return 1;
                                                 return 0; // default return value (no sorting)
                                             });
                                             for (
                                                 var i = 0;
                                                 i < assetTypeAttributeValuesList.length;
                                                 i++
                                             ) {
                                                 var home = assetTypeAttributeValuesList[i];
                                                 infoStr +=
                                                     "<br><b>" +
                                                     home.assetTypeAttribute.name +
                                                     "</b> : " +
                                                     home.attributeValue;
                                                 otdr = home;
                                             } */
                                            userContent.innerHTML =
                                                '<div class="infoWindowhead">' +
                                                assetDetails.name +
                                                '</div><div class="infoWindowContent">' +
                                                "<b>Asset Type </b> : " +
                                                assetDetails.assetTypeName +
                                                "<br><b>Phase </b> : " +
                                                assetDetails.phase +
                                                "<br>" +
                                                infoStr +
                                                "</div><br>";

                                            return function () {
                                                infowindow.setContent(userContent);
                                                infowindow.open(locations.map, marker);
                                                infowindow.setPosition(
                                                    new google.maps.LatLng(
                                                        marker.getPosition().lat(),
                                                        marker.getPosition().lng()
                                                    )
                                                );
                                                setTimeout(function () {
                                                    infowindow.close();
                                                }, 50000);
                                            };
                                        })(marker, index)
                                    );

                                    /*  markerSymbol = new google.maps.Marker({
                                         icon:  {
                                             path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                             strokeColor: tempColor[item.phase],
                                             fillColor: tempColor[item.phase],
                                             fillOpacity:1,
                                             scale: 1,
                                             labelOrigin: new google.maps.Point(-1, 7)
                                         },
                                         map: locations.map,
                                         position:  { lat: each.latitude, lng: each.longitude },
                                         name:item.phase
                                     });  */
                                    phaseMarkers.push(marker);
                                    // phaseMarkers.push(markerSymbol);
                                })
                                //createMarkers(assetsList, 'content/images/chainagePoints.png', locations.map,"assets")
                                //createMarkers(assetsList, item.assetType.imagePath , locations.map, "assets")
                            }

                            if (item.assetTypeLayout == "SPREAD" && item.phase != null) {
                                isObjBool[item.phase] = true
                                assetSpreadListPlanning = []
                                assetSpreadListMaintenance = []
                                assetSpreadListRollout = []
                                assetSpreadListPlanSurvey = []
                                item.assetCoordinates.forEach(function (each) {
                                    callSwitchPhaseSpread(each, item.phase, item.name, item.block)
                                })
                                callPhaseSpread()
                                //createMarkers(assetsList, 'content/images/chainagePoints.png', locations.map,"assets")
                                //createMarkers(assetsList, item.assetType.imagePath , locations.map, "assets")
                            }
                        }

                    })

                    callObjPhases()
                }

                if (data.assets.length >= 50) {
                    callAssetsRouteByID(vm.trRoute.id, data.assets[data.assets.length - 1].id)
                }
            })
        }
        function getAssetsByRoute() {
            RouteAssetsById.get(vm.trRoute.id, function (response) {
                vm.assets = response.assets;
                var colorMap = {
                    "Chamber": "#FF0000",
                    "Pole": "#0000FF"
                };
                vm.assets.forEach(function (asset) {
                    var coordinates = asset.assetCoordinates.map(function (coord) {
                        return { lat: coord.latitude, lng: coord.longitude };
                    });
                    if (asset.assetTypeLayout === 'FIXED') {
                        var marker = new google.maps.Marker({
                            position: coordinates[0],
                            map: locations.map,
                            title: asset.name,
                            icon: {
                                url: asset.assetTypeImagePath,
                                scaledSize: new google.maps.Size(32, 32)
                            }
                        });
                        var infowindow = new google.maps.InfoWindow();
                        google.maps.event.addListener(marker, 'click', function () {
                            if (openedInfoWindow !== null) {
                                openedInfoWindow.close();
                            }
                            var contentString = '<div class="infoWindowhead">' +
                                "<b>ID: " + asset.id + "</b><br>" +
                                "<b>Name: " + asset.name + "</b><br>" +
                                "<b>AssetTypeName: " + asset.assetTypeName + "</b><br>" +
                                "<b>Latitude: " + coordinates[0].lat + "</b><br>" +
                                "<b>Longitude: " + coordinates[0].lng + "</b><br>" +
                                "</div>";
                            infowindow.setContent(contentString);
                            infowindow.open(locations.map, marker);
                            openedInfoWindow = infowindow;
                        });
                    } else if (asset.assetTypeLayout === 'SPREAD') {
                        var polylineColor = colorMap[asset.assetTypeName] || '#0000FF';
                        var drawPolyline = new google.maps.Polyline({
                            path: coordinates,
                            geodesic: true,
                            strokeColor: polylineColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 2
                        });
                        drawPolyline.setMap(locations.map);
                        var polylineInfoWindow = new google.maps.InfoWindow();
                        google.maps.event.addListener(drawPolyline, 'click', function (event) {
                            if (openedInfoWindow !== null) {
                                openedInfoWindow.close();
                            }

                            var contentString = '<div class="infoWindowhead">' +
                                "<b>ID: " + asset.id + "</b><br>" +
                                "<b>Name: " + asset.name + "</b><br>" +
                                "<b>AssetTypeName: " + asset.assetTypeName + "</b><br>" +
                                "<b>Coordinates: </b><br>";
                            coordinates.forEach(function (coord, index) {
                                contentString += "Point " + (index + 1) + ": " +
                                    "Lat: " + coord.lat + ", Lng: " + coord.lng + "<br>";
                            });
                            contentString += "</div>";
                            polylineInfoWindow.setContent(contentString);
                            polylineInfoWindow.setPosition(event.latLng);
                            polylineInfoWindow.open(locations.map);
                            openedInfoWindow = polylineInfoWindow;
                        });
                    }
                });
            });
        }

        setTimeout(function () {
            getAssetsByRoute();
        }, 3000);
        vm.page = 1;
        vm.predicate = 'modified_date';
        vm.itemsPerPage = 10;
        vm.mtrpage = 1;
        vm.mtrPerPage = 10;
        vm.materialTxnByDates = [];
        vm.materialByDate = [];
        vm.loadMaterialTxnDate = function () {
            MaterialDates.query({
                type: "route",
                id: entity.id,
                page: vm.mtrpage - 1,
                size: vm.mtrPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.TotalItems = headers('X-Total-Count');
                vm.QueryCount = vm.TotalItems;
                vm.materialByDate = data.map(function (item) {
                    return {
                        timestamp: item[0],
                        total: item[1],
                        isExpand: false
                    };
                });

            });
        };
        vm.loadMaterialTxnDate();
        vm.loadMaterialTxnByDates = function (date) {
            MaterialByDates.query({
                type: 'route',
                id: entity.id,
                date: date,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.materialTxnByDates = data.map(function (item) {
                    return {
                        transactionId: item[0],
                        timestamp: item[4],
                        transactionName: item[1],
                        transactionType: item[2],
                        count: item[3],
                        status: item[5],
                    };
                });
            });
        };
        vm.toggleExpand = function (selectedTask) {
            angular.forEach(vm.materialByDate, function (task) {
                if (task === selectedTask) {
                    task.isExpand = !task.isExpand;
                    if (task.isExpand) {
                        vm.loadMaterialTxnByDates(task.timestamp);
                    } else {
                        vm.materialTxnByDates = [];
                    }
                } else {
                    task.isExpand = false;
                }
            });
        };
        vm.transitionMainMateril = function () {
            vm.loadMaterialTxnDate()
        }
        vm.fetchTerrain = function () {
            RouteByID.get(vm.trRoute.id, function (response) {
                vm.routePathResponse = response;
                callRoutePath(vm.routePathResponse.routePoints);
            });
            function callRoutePath(routePaths) {
                var colors = ['lightgreen', 'orange'];
                var pathColor = colors[0];
                var proposedPathColor = colors[1];
                var plannedCoordinates = [];
                var proposedCoordinates = [];
                routePaths.forEach(function (coord) {
                    if (coord.routePointType === 'COORDINATE') {
                        if (coord.phase == 'PLANNING') {
                            plannedCoordinates.push(coord);
                        } else if (coord.phase == 'PROPOSED') {
                            proposedCoordinates.push(coord);
                        }
                    }
                });
                var pathCoordinates = plannedCoordinates.map(function (coord) {
                    var latLng = { lat: coord.latitude, lng: coord.longitude };
                    bounds.extend(latLng);
                    return latLng;
                });
                var pathPolyline = new google.maps.Polyline({
                    path: pathCoordinates,
                    geodesic: true,
                    strokeColor: pathColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 4
                });
                pathPolyline.setMap(vm.newMap);

                var proposedPathCoordinates = proposedCoordinates.map(function (coord) {
                    var latLng = { lat: coord.latitude, lng: coord.longitude };
                    bounds.extend(latLng);
                    return latLng;
                });
                var proposedPathPolyline = new google.maps.Polyline({
                    path: proposedPathCoordinates,
                    geodesic: true,
                    strokeColor: proposedPathColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 4
                });
                proposedPathPolyline.setMap(vm.newMap);
            }
            var bounds = new google.maps.LatLngBounds();
            vm.newMap = new google.maps.Map(
                document.getElementById("map_canvasStarts"),
                myOption($rootScope.rolloutMapInitZoom, center)
            );
            TrRouteForMap.getTerrainPathByRoute(vm.trRoute.id, function (response) {
                vm.terrainPathResponse = response;
                callTerrainPath(vm.terrainPathResponse);
            });
            function callTerrainPath(terrainPaths) {
                vm.terrainPolylines = {};
                vm.terrainMarkers = {};
                terrainPaths.forEach(function (pathGroup) {
                    vm.terrainColorMap = {};
                    vm.terrainListResponse.forEach(function (terrain) {
                        vm.terrainColorMap[terrain.id] = terrain.colorcode
                    });
                    var pathColor = vm.terrainColorMap[pathGroup.id]
                    if (pathGroup.value && Array.isArray(pathGroup.value.COORDINATE)) {
                        var pathCoordinates = pathGroup.value.COORDINATE.map(function (coord) {
                            var latLng = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(latLng);
                            return latLng;
                        });
                        var pathPolyline = new google.maps.Polyline({
                            path: pathCoordinates,
                            geodesic: true,
                            strokeColor: pathColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 12
                        });
                        pathPolyline.setMap(vm.newMap);
                        vm.terrainPolylines[pathGroup.id] = pathPolyline;
                        google.maps.event.addListener(pathPolyline, 'click', function (event) {
                            if (openedInfoWindow != null) {
                                openedInfoWindow.close();
                            }

                            var contentString = '<div class="infoWindowhead"></br></br>' +
                                "<b>Latitude : " + event.latLng.lat().toFixed(6) + "</b></br>" +
                                "<b>Longitude : " + event.latLng.lng().toFixed(6) + "</b></br>" +
                                "<b></b></br>" +
                                "</br></div>";

                            infowindow.setContent(contentString);
                            infowindow.setPosition(event.latLng);
                            infowindow.open(vm.newMap);
                            openedInfoWindow = infowindow;
                        });
                        google.maps.event.addListener(pathPolyline, 'mouseover', function () {
                            vm.highlightTableRow(pathGroup.id, 'terrain');
                        });
                        google.maps.event.addListener(pathPolyline, 'mouseout', function () {
                            vm.resetTableRowHighlight(pathGroup.id);
                        });
                    }
                });
                vm.newMap.fitBounds(bounds);
            }
            vm.terrainListResponse;
            vm.uniquePhases = [];
            var phasesMap = {};
            vm.isVerifiedFilter = false;
            vm.isNotVerifiedFilter = false;
            vm.isDeviatedFilter = false;
            for (var i = 0; i < vm.terrainListResponse.length; i++) {
                var phase = vm.terrainListResponse[i].phase;
                if (!phasesMap[phase]) {
                    vm.uniquePhases.push({ phase: phase, selected: false });
                    phasesMap[phase] = true;
                }
            }

            vm.uniqueTerrainTypes = [];
            vm.filteredTerrainList = vm.terrainListResponse;
            vm.updateTerrainTypes = function () {
                var selectedPhases = [];

                for (var j = 0; j < vm.uniquePhases.length; j++) {
                    if (vm.uniquePhases[j].selected) {
                        selectedPhases.push(vm.uniquePhases[j].phase);
                    }
                }
                if (selectedPhases.length === 0) {
                    vm.uniqueTerrainTypes = [];
                }

                vm.uniqueTerrainTypes = [];
                var terrainTypesMap = {};
                for (var k = 0; k < vm.terrainListResponse.length; k++) {
                    var terrain = vm.terrainListResponse[k];

                    var isPhaseMatched = selectedPhases.indexOf(terrain.phase) !== -1;
                    var isVerifiedMatch = (vm.isVerifiedFilter && terrain.isVerified === true) ||
                        (vm.isNotVerifiedFilter && terrain.isVerified === false) ||
                        (!vm.isVerifiedFilter && !vm.isNotVerifiedFilter);

                    var isDeviatedMatch = (vm.isDeviatedFilter && terrain.isDeviated === true) ||
                        (!vm.isDeviatedFilter);

                    if (isPhaseMatched && isVerifiedMatch && isDeviatedMatch) {
                        if (!terrainTypesMap[terrain.typeName]) {
                            vm.uniqueTerrainTypes.push({
                                typeName: terrain.typeName,
                                colorcode: terrain.colorcode,
                                selected: false
                            });
                            terrainTypesMap[terrain.typeName] = true;
                        }
                    }
                }

                for (var id in vm.terrainPolylines) {
                    if (vm.terrainPolylines.hasOwnProperty(id)) {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 5 });
                    }
                }

                vm.areFiltersSelected = function () {
                    var selectedPhases = vm.uniquePhases.some(function (phase) {
                        return phase.selected;
                    });
                    var selectedTerrainTypes = vm.uniqueTerrainTypes.some(function (terrain) {
                        return terrain.selected;
                    });
                    var selectedVerification = vm.isVerifiedFilter || vm.isNotVerifiedFilter || vm.isDeviatedFilter;
                    return selectedPhases || selectedTerrainTypes || selectedVerification;
                };
            };

            vm.applyFilters = function () {
                var selectedPhases = [];
                var selectedTerrainTypes = [];

                for (var l = 0; l < vm.uniquePhases.length; l++) {
                    if (vm.uniquePhases[l].selected) {
                        selectedPhases.push(vm.uniquePhases[l].phase);
                    }
                }

                var filteredList = vm.terrainListResponse.filter(function (terrain) {
                    return selectedPhases.length === 0 || selectedPhases.indexOf(terrain.phase) !== -1;
                });

                var isVerifiedOrNotChecked = vm.isVerifiedFilter || vm.isNotVerifiedFilter;
                var isDeviatedChecked = vm.isDeviatedFilter;

                filteredList = filteredList.filter(function (terrain) {
                    return (!isVerifiedOrNotChecked) ||
                        (vm.isVerifiedFilter && terrain.isVerified === true) ||
                        (vm.isNotVerifiedFilter && (terrain.isVerified === false || terrain.isVerified === null));
                });

                filteredList = filteredList.filter(function (terrain) {
                    return (!isDeviatedChecked) || (vm.isDeviatedFilter && terrain.isDeviated === true);
                });

                var availableTerrainTypes = [];
                filteredList.forEach(function (terrain) {
                    if (availableTerrainTypes.indexOf(terrain.typeName) === -1) {
                        availableTerrainTypes.push(terrain.typeName);
                    }
                });

                vm.uniqueTerrainTypes.forEach(function (terrainType) {
                    terrainType.isAvailable = availableTerrainTypes.indexOf(terrainType.typeName) !== -1;
                });

                for (var m = 0; m < vm.uniqueTerrainTypes.length; m++) {
                    if (vm.uniqueTerrainTypes[m].selected) {
                        selectedTerrainTypes.push(vm.uniqueTerrainTypes[m].typeName);
                    }
                }

                filteredList = filteredList.filter(function (terrain) {
                    return selectedTerrainTypes.length === 0 || selectedTerrainTypes.indexOf(terrain.typeName) !== -1;
                });

                for (var id in vm.terrainPolylines) {
                    if (vm.terrainPolylines.hasOwnProperty(id)) {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 0 });
                    }
                }

                filteredList.forEach(function (terrain) {
                    var terrainId = terrain.id;
                    if (vm.terrainPolylines[terrainId]) {
                        vm.terrainPolylines[terrainId].setOptions({ strokeWeight: 12, strokeColor: terrain.colorcode });
                    }
                });

                vm.filteredTerrainList = filteredList;
                vm.isFilterMenuOpen = false;
            };

            vm.resetFilters = function () {
                for (var o = 0; o < vm.uniquePhases.length; o++) {
                    vm.uniquePhases[o].selected = false;
                }
                for (var p = 0; p < vm.uniqueTerrainTypes.length; p++) {
                    vm.uniqueTerrainTypes[p].selected = false;
                }
                vm.isVerifiedFilter = false;
                vm.isNotVerifiedFilter = false;
                vm.isDeviatedFilter = false;
                for (var id in vm.terrainPolylines) {
                    if (vm.terrainPolylines.hasOwnProperty(id)) {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 5 });
                    }
                }
                vm.uniqueTerrainTypes = []
                vm.filteredTerrainList = vm.terrainListResponse;
            };
            vm.closeFilters = function () {
                vm.isFilterMenuOpen = false;

            };
        };
        vm.highlightTerrain = function (terrainId) {
            for (var id in vm.terrainPolylines) {
                if (id == terrainId) {
                    vm.terrainPolylines[id].setOptions({ strokeWeight: 12 });
                } else {
                    vm.terrainPolylines[id].setOptions({ strokeWeight: 5 });
                }
            }
        };
        vm.resetTerrain = function () {

            for (var id in vm.terrainPolylines) {
                if (vm.terrainPolylines.hasOwnProperty(id)) {
                    if (vm.filteredTerrainList.find(function (terrain) {
                        return terrain.id == id
                    })) {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 12 });
                    } else {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 5 });
                    }
                }
            }
        };
        vm.highlightRow = function (rowId) {
            for (var id in vm.rowPolylines) {
                if (id == rowId) {
                    vm.rowPolylines[id].setOptions({ strokeWeight: 12 });
                } else {
                    vm.rowPolylines[id].setOptions({ strokeWeight: 5 });
                }
            }
        };
        vm.resetRow = function () {
            for (var id in vm.rowPolylines) {
                if (vm.rowPolylines.hasOwnProperty(id)) {
                    if (vm.filteredRowList.find(function (row) {
                        return row.id == id
                    })) {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 12 });
                    } else {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 5 });
                    }
                }
            }
        };
        vm.highlightTableRow = function (id, type) {
            var rows = document.querySelectorAll('.highlighted-row');
            rows.forEach(function (row) {
                row.classList.remove('highlighted-row');
            });
            var row = document.getElementById(type + '-' + id);
            if (row) {
                row.classList.add('highlighted-row');
            }
        };
        vm.resetTableRowHighlight = function (id) {
            var row = document.getElementById('terrain-' + id) || document.getElementById('row-' + id);
            if (row) {
                row.classList.remove('highlighted-row');
            }
        };
        vm.isFilterMenuOpen = false;
        vm.toggleFilterMenu = function () {
            vm.isFilterMenuOpen = !vm.isFilterMenuOpen;
        };
        vm.fetchRow = function () {
            RouteByID.get(vm.trRoute.id, function (response) {
                vm.routePathResponse = response;
                callRoutePath(vm.routePathResponse.routePoints);
            });
            function callRoutePath(routePaths) {
                var colors = ['lightgreen', 'orange'];
                var pathColor = colors[0];
                var proposedPathColor = colors[1];
                var plannedCoordinates = [];
                var proposedCoordinates = [];
                routePaths.forEach(function (coord) {
                    if (coord.routePointType === 'COORDINATE') {
                        if (coord.phase == 'PLANNING') {
                            plannedCoordinates.push(coord);
                        } else if (coord.phase == 'PROPOSED') {
                            proposedCoordinates.push(coord);
                        }
                    }
                });
                var pathCoordinates = plannedCoordinates.map(function (coord) {
                    var latLng = { lat: coord.latitude, lng: coord.longitude };
                    bounds.extend(latLng);
                    return latLng;
                });
                var pathPolyline = new google.maps.Polyline({
                    path: pathCoordinates,
                    geodesic: true,
                    strokeColor: pathColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 4
                });
                pathPolyline.setMap(vm.newMaps);

                var proposedPathCoordinates = proposedCoordinates.map(function (coord) {
                    var latLng = { lat: coord.latitude, lng: coord.longitude };
                    bounds.extend(latLng);
                    return latLng;
                });
                var proposedPathPolyline = new google.maps.Polyline({
                    path: proposedPathCoordinates,
                    geodesic: true,
                    strokeColor: proposedPathColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 4
                });
                proposedPathPolyline.setMap(vm.newMaps);
            }
            var markers = [];
            var openedInfoWindow = null;
            var bounds = new google.maps.LatLngBounds();
            vm.newMaps = new google.maps.Map(
                document.getElementById("map_canvasStartss"),
                myOption($rootScope.rolloutMapInitZoom, center)
            );
            TrRouteForMap.getRoWPathByRoute(vm.trRoute.id, function (response) {
                vm.rowPathResponse = response;
                callRowPath(vm.rowPathResponse);
            });
            function callRowPath(rowPaths) {
                vm.rowPolylines = {};
                vm.rowMarkers = {};
                rowPaths.forEach(function (pathGroup) {
                    vm.rowColorMap = {};
                    vm.rowListResponse.forEach(function (row) {
                        vm.rowColorMap[row.id] = row.colorcode;
                    });
                    var pathColor = vm.rowColorMap[pathGroup.id]
                    if (pathGroup.value && Array.isArray(pathGroup.value.COORDINATE)) {
                        var pathCoordinates = pathGroup.value.COORDINATE.map(function (coord) {
                            var latLng = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(latLng);
                            return latLng;
                        });
                        var pathPolyline = new google.maps.Polyline({
                            path: pathCoordinates,
                            geodesic: true,
                            strokeColor: pathColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 12
                        });
                        pathPolyline.setMap(vm.newMaps);
                        vm.rowPolylines[pathGroup.id] = pathPolyline;
                        var infowindow = new google.maps.InfoWindow();
                        google.maps.event.addListener(pathPolyline, 'click', function (event) {
                            if (openedInfoWindow != null) {
                                openedInfoWindow.close();
                            }

                            var contentString = '<div class="infoWindowhead"></br></br>' +
                                "<b>Latitude : " + event.latLng.lat().toFixed(6) + "</b></br>" +
                                "<b>Longitude : " + event.latLng.lng().toFixed(6) + "</b></br>" +
                                "<b></b></br>" +
                                "</br></div>";

                            infowindow.setContent(contentString);
                            infowindow.setPosition(event.latLng);
                            infowindow.open(vm.newMaps);
                            openedInfoWindow = infowindow;
                        });
                        google.maps.event.addListener(pathPolyline, 'mouseover', function () {
                            vm.highlightTableRow(pathGroup.id, 'row');
                        });
                        google.maps.event.addListener(pathPolyline, 'mouseout', function () {
                            vm.resetTableRowHighlight(pathGroup.id);
                        });
                    }
                    if (pathGroup.value && Array.isArray(pathGroup.value.MARKER)) {
                        pathGroup.value.MARKER.forEach(function (coord) {
                            var markerPosition = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(markerPosition);
                            var marker = new google.maps.Marker({
                                position: markerPosition,
                                map: vm.newMaps,
                                icon: {
                                    url: 'content/images/markersPoint.png',
                                    scaledSize: new google.maps.Size(iconSize, iconSize),
                                    anchor: new google.maps.Point(
                                        iconSize / 2,
                                        iconSize / 2
                                    ),
                                }
                            });
                            markers.push(marker);
                            markerList.push(marker);

                        });
                        vm.rowMarkers[pathGroup.id] = markerList;
                    }
                    vm.newMaps.fitBounds(bounds);
                });
            }

            vm.rowListResponse;
            vm.uniquePhases = [];
            var phasesMap = {};
            for (var i = 0; i < vm.rowListResponse.length; i++) {
                var phase = vm.rowListResponse[i].phase;
                if (!phasesMap[phase]) {
                    vm.uniquePhases.push({ phase: phase, selected: false });
                    phasesMap[phase] = true;
                }
            }
            vm.uniqueRowTypes = [];
            vm.filteredRowList = vm.rowListResponse;
            vm.updateRowTypes = function () {
                var selectedPhases = [];
                for (var j = 0; j < vm.uniquePhases.length; j++) {
                    if (vm.uniquePhases[j].selected) {
                        selectedPhases.push(vm.uniquePhases[j].phase);
                    }
                }
                if (selectedPhases.length === 0) {
                    vm.uniqueRowTypes = [];

                }
                vm.uniqueRowTypes = [];
                var rowTypesMap = {};
                for (var k = 0; k < vm.rowListResponse.length; k++) {
                    var row = vm.rowListResponse[k];
                    var isPhaseMatched = selectedPhases.indexOf(row.phase) !== -1;
                    var isVerifiedMatch = (vm.isVerifiedFilter && row.isVerified === true) ||
                        (vm.isNotVerifiedFilter && row.isVerified === false) ||
                        (!vm.isVerifiedFilter && !vm.isNotVerifiedFilter);

                    var isDeviatedMatch = (vm.isDeviatedFilter && row.isDeviated === true) ||
                        (!vm.isDeviatedFilter);

                    if (isPhaseMatched && isVerifiedMatch && isDeviatedMatch) {
                        if (!rowTypesMap[row.typeName]) {
                            vm.uniqueRowTypes.push({
                                typeName: row.typeName,
                                colorcode: row.colorcode,
                                selected: false
                            });
                            rowTypesMap[row.typeName] = true;
                        }
                    }
                }
                for (var id in vm.rowPolylines) {
                    if (vm.rowPolylines.hasOwnProperty(id)) {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 5 });
                    }
                }
                vm.areFiltersSelected = function () {
                    var selectedPhases = vm.uniquePhases.some(function (phase) {
                        return phase.selected;
                    });
                    var selectedRowTypes = vm.uniqueRowTypes.some(function (row) {
                        return row.selected;
                    });
                    var selectedVerification = vm.isVerifiedFilter || vm.isNotVerifiedFilter || vm.isDeviatedFilter;
                    return selectedPhases || selectedRowTypes || selectedVerification;
                };

            };
            vm.applyFilters = function () {
                var selectedPhases = [];
                var selectedRowTypes = [];
                for (var l = 0; l < vm.uniquePhases.length; l++) {
                    if (vm.uniquePhases[l].selected) {
                        selectedPhases.push(vm.uniquePhases[l].phase);
                    }
                }
                var filteredList = vm.rowListResponse.filter(function (row) {
                    return selectedPhases.length === 0 || selectedPhases.indexOf(row.phase) !== -1;
                });

                var isVerifiedOrNotChecked = vm.isVerifiedFilter || vm.isNotVerifiedFilter;
                var isDeviatedChecked = vm.isDeviatedFilter;

                filteredList = filteredList.filter(function (row) {
                    return (!isVerifiedOrNotChecked) ||
                        (vm.isVerifiedFilter && row.isVerified === true) ||
                        (vm.isNotVerifiedFilter && (row.isVerified === false || row.isVerified === null));
                });

                filteredList = filteredList.filter(function (row) {
                    return (!isDeviatedChecked) || (vm.isDeviatedFilter && row.isDeviated === true);
                });

                var availableRowTypes = [];
                filteredList.forEach(function (row) {
                    if (availableRowTypes.indexOf(row.typeName) === -1) {
                        availableRowTypes.push(row.typeName);
                    }
                });

                vm.uniqueRowTypes.forEach(function (rowType) {
                    rowType.isAvailable = availableRowTypes.indexOf(rowType.typeName) !== -1;
                });

                for (var m = 0; m < vm.uniqueRowTypes.length; m++) {
                    if (vm.uniqueRowTypes[m].selected) {
                        selectedRowTypes.push(vm.uniqueRowTypes[m].typeName);
                    }
                }

                filteredList = filteredList.filter(function (row) {
                    return selectedRowTypes.length === 0 || selectedRowTypes.indexOf(row.typeName) !== -1;
                });
                for (var id in vm.rowPolylines) {
                    if (vm.rowPolylines.hasOwnProperty(id)) {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 0 });
                    }
                }
                filteredList.forEach(function (row) {
                    var rowId = row.id;
                    if (vm.rowPolylines[rowId]) {
                        vm.rowPolylines[rowId].setOptions({ strokeWeight: 14, strokeColor: row.colorcode });
                    }
                });
                vm.filteredRowList = filteredList;
                vm.isFilterMenuOpen = false;
            };
            vm.resetFilters = function () {
                for (var o = 0; o < vm.uniquePhases.length; o++) {
                    vm.uniquePhases[o].selected = false;
                }
                for (var p = 0; p < vm.uniqueRowTypes.length; p++) {
                    vm.uniqueRowTypes[p].selected = false;
                }
                vm.isVerifiedFilter = false;
                vm.isNotVerifiedFilter = false;
                vm.isDeviatedFilter = false;
                for (var id in vm.rowPolylines) {
                    if (vm.rowPolylines.hasOwnProperty(id)) {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 5 });
                    }
                }

                vm.uniqueRowTypes = []
                vm.filteredRowList = vm.rowListResponse;
            };
            vm.closeFilters = function () {
                vm.isFilterMenuOpen = false;
            };
        };
        vm.SectionMap = new google.maps.Map(
            document.getElementById("SectionMap_canvasStart"),
            myOption($rootScope.rolloutMapInitZoom, center)
        );
        if (vm.trRoute && vm.trRoute.routePoints != null && vm.trRoute.routePoints.length) {
            vm.trRoute.routePoints.sort(function (a, b) { return a.routePointNumber - b.routePointNumber });
            var pathCoordinates = [];

            //route path
            vm.trRoute.routePoints.forEach(function (each) {
                if (each.routePointType == "COORDINATE" && each.phase != null) {
                    var coordinate = { lat: each.latitude, lng: each.longitude };
                    pathCoordinates.push(coordinate);
                }
            });
            if (pathCoordinates.length) {
                var polyline = new google.maps.Polyline({
                    path: pathCoordinates,
                    geodesic: true,
                    strokeColor: "red",
                    strokeOpacity: 1.0,
                    strokeWeight: 8,
                });
                polyline.setMap(vm.SectionMap);

            }
        }

        vm.getSectionPath = function () {
            //section path
            vm.sectionPolyline = {}
            routeDetail.getRoutesDetails(entity.id, function (response) {
                vm.sectionResponse = response
                console.log(vm.sectionResponse)
                vm.sectionResponse.forEach(function (section) {
                    TaskSectionById.get(section.sectionId, function (sectionVal) {
                        var sectionPathResponse = sectionVal;
                        var polylineCoordinates = []
                        var bounds = new google.maps.LatLngBounds();
                        sectionPathResponse.COORDINATE.forEach(function (coordinate) {
                            var latLng = {
                                lat: coordinate.latitude,
                                lng: coordinate.longitude
                            };
                            polylineCoordinates.push(latLng);
                            bounds.extend(latLng);
                            var polyline = new google.maps.Polyline({
                                path: polylineCoordinates,
                                geodesic: true,
                                strokeColor: '#FD7A24',
                                strokeOpacity: 0.5,
                                strokeWeight: 7
                            });
                            polyline.setMap(vm.SectionMap);
                            vm.sectionPolyline[section.sectionId] = polyline
                        });
                    })
                })

            })
            vm.dailyLogPolyline = {}
            vm.taskPolyline = {}
            vm.sectionResponse.forEach(function (task) {
                Inventory.taskBySection(task.sectionId, function (response) {
                    vm.taskLogResponse = response;
                    vm.taskLogResponse.forEach(function (task) {
                        console.log()
                        Inventory.taskPathByID(task.id, function (response) {
                            console.log(response)
                            if (response.COORDINATE !== undefined) {
                                var polylineCoordinates = [];
                                response.COORDINATE.forEach(function (coordinate) {
                                    if (coordinate.routePointType === 'COORDINATE') {
                                        var latLng = {
                                            lat: coordinate.latitude,
                                            lng: coordinate.longitude
                                        };
                                        polylineCoordinates.push(latLng);
                                    }
                                });
                                var polyline = new google.maps.Polyline({
                                    path: polylineCoordinates,
                                    geodesic: true,
                                    strokeColor: 'green',
                                    strokeOpacity: 0.8,
                                    strokeWeight: 5
                                });
                                polyline.setMap(vm.SectionMap);
                                vm.taskPolyline[task.id] = polyline
                            }
                        });
                    });
                });
            })

        }
        setTimeout(function () {
            vm.getSectionPath()
        }, 2000)
        vm.expandTask = function (taskId, item) {
            vm.expandResponse.forEach(function (t) {
                if (t !== item) {
                    t.isExpanded = false;
                }
            });
            item.isExpanded = !item.isExpanded;
            if (item.isExpanded) {
                dailyLogByTaskId.query(
                    {
                        id: taskId
                    }, function onSuccess(response) {
                        vm.dailyLogs = response;
                        Object.values(vm.dailyLogPolyline).forEach(function (polyline) {
                            polyline.setMap(null);
                        });
                        // var colors = ['#fe9ebc', '#8338ec', '#fe6259', '#fe2400', '#00658d', '#ff66d8', '#4cc9f0'];
                        vm.dailyLogs.forEach(function (coor, index) {
                            // var pathColor = colors[index % colors.length];
                            var fromLatLng = new google.maps.LatLng(coor.fromRoutePointLat, coor.fromRoutePointLng);
                            var toLatLng = new google.maps.LatLng(coor.toRoutePointLat, coor.toRoutePointLng);

                            var polyline = new google.maps.Polyline({
                                path: [fromLatLng, toLatLng],
                                geodesic: true,
                                strokeColor: '#06d6a0',
                                strokeOpacity: 1.0,
                                strokeWeight: 5
                            });
                            polyline.setMap(vm.SectionMap);
                            vm.dailyLogPolyline[coor.id] = polyline;
                        });
                    }
                )
            }
        }
        vm.startPoint = null;
        vm.endPoint = null;
        vm.highlightLog = function (id) {
            var polyline = vm.dailyLogPolyline[id];
            if (polyline) {
                polyline.setOptions({
                    strokeWeight: 16
                });
                var path = polyline.getPath().getArray();
                var fromLatLng = path[0];
                var toLatLng = path[path.length - 1];
                if (vm.startPoint) {
                    vm.startPoint.setMap(null);
                }
                if (vm.endPoint) {
                    vm.endPoint.setMap(null);
                }
                vm.startPoint = new google.maps.Marker({
                    position: fromLatLng,
                    map: vm.SectionMap,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: "content/images/sections.png",
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            -4
                        ),
                        labelOrigin: new google.maps.Point(25, 22)
                    },
                    label: {
                        text: 'Start',
                        color: '#000000',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                });
                vm.endPoint = new google.maps.Marker({
                    position: toLatLng,
                    map: vm.SectionMap,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: "content/images/sections.png",
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            -4
                        ),
                        labelOrigin: new google.maps.Point(25, 22)
                    },
                    label: {
                        text: 'End',
                        color: '#000000',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                });
            }
        };
        vm.resetLog = function () {
            Object.keys(vm.dailyLogPolyline).forEach(function (key) {
                var polyline = vm.dailyLogPolyline[key];
                if (polyline) {
                    polyline.setOptions({
                        strokeWeight: 5
                    });
                }
            });
            if (vm.startPoint) {
                vm.startPoint.setMap(null);
                vm.startPoint = null;
            }
            if (vm.endPoint) {
                vm.endPoint.setMap(null);
                vm.endPoint = null;
            }
        };
        vm.highlightTask = function (taskId) {
            for (var id in vm.taskPolyline) {
                if (id == taskId) {
                    vm.taskPolyline[id].setOptions({ strokeWeight: 12 });
                } else {
                    vm.taskPolyline[id].setOptions({ strokeWeight: 5 });
                }
            }
        }
        vm.resetTask = function () {
            for (var id in vm.taskPolyline) {
                if (vm.taskPolyline.hasOwnProperty(id)) {
                    vm.taskPolyline[id].setOptions({ strokeWeight: 5 });
                }
            }
        }
        vm.highlightRoute = function (sectionId) {
            console.log("hellos")
            console.log(sectionId)
            for (var id in vm.sectionPolyline) {
                if (vm.sectionPolyline.hasOwnProperty(id)) {
                    if (id == sectionId) {
                        vm.sectionPolyline[id].setOptions({ strokeWeight: 12 });
                    } else {
                        vm.sectionPolyline[id].setOptions({ strokeWeight: 5 });
                    }
                }
            }
        }
        vm.resetRoute = function () {
            for (var id in vm.sectionPolyline) {
                if (vm.sectionPolyline.hasOwnProperty(id)) {
                    vm.sectionPolyline[id].setOptions({ strokeWeight: 5 });
                }
            }
        }

    }
})();